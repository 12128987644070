import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import * as userAction from 'store/user/user_actions';
import CountdownTimer from '../../components/countdown_timer';
import Backbutton from '../../components/back_button';
import { toast } from 'react-toastify';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import styled from 'styled-components';
import {
  OTP_EXPIRATION_TIME_IN_SECONDS,
  PHONE_OTP_CODE_LENGTH,
} from 'silal_app_base_react/src/config/constants';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button, InputOtp } from '@nextui-org/react';

function PhoneLoginOTPScreen() {
  // states
  const [targetedTime, setTargetedTime] = useState(
    OTP_EXPIRATION_TIME_IN_SECONDS * 1000,
  );
  const [currentTime, setCurrentTime] = useState(+new Date());
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setotp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state) {
      setPhoneNumber(location.state.phoneNo);
    }
  }, [location.state]);

  const handleOtpChange = (otpValue: string) => {
    setotp(otpValue);
    if (otpValue.length === PHONE_OTP_CODE_LENGTH) {
      verifyOtp({ otpValue });
    }
  };

  function resendCode() {
    const now = Date.now();
    if (now > currentTime + targetedTime) {
      setTargetedTime(OTP_EXPIRATION_TIME_IN_SECONDS * 1000);
      setCurrentTime(Date.now());
      sendOtpAgain();
    }
    return false;
  }

  const verifyOtp = ({ otpValue }: { otpValue?: string }) => {
    const otpToSend = otpValue || otp;
    if (!otpToSend) {
      toast.error('Please enter OTP code before proceeding.');
      return;
    } else if (otpToSend.length < PHONE_OTP_CODE_LENGTH) {
      toast.error(
        `Please enter the ${PHONE_OTP_CODE_LENGTH} digit OTP code you received.`,
      );
      return;
    }
    if (location.state.previousPage === 'signup') {
      const data = {
        otp: otpToSend,
        phone: phoneNumber,
      };
      const payload = {
        data,
        success: () => {
          navigate('/signup-email');
        },
        failure: (err: any) => {
          // TODO : fix any
          toast.error(err.msg);
        },
      };
      dispatch(userAction.actionSignupPhoneVerifyOtp(payload));
    } else if (location.state.previousPage === 'login') {
      const data = {
        otp: otpToSend,
        phone: phoneNumber,
      };
      const payload = {
        data,
      };
      dispatch(userAction.actionLoginOrVerifyPhoneOtp(payload));
    }
  };

  const sendOtpAgain = async () => {
    if (location.state.previousPage === 'login') {
      await AuthenticationRepository.loginSendPhoneOTP(phoneNumber).then(
        (res) => {
          if (!res) return;
          toast.success(
            'A new verification code has been sent to ' + phoneNumber,
          );
        },
      );
    } else if (location.state.previousPage === 'signup') {
      await AuthenticationRepository.signupSendPhoneOTP(phoneNumber);
    }
  };

  return (
    <BackgroundContainer>
      <Wrapper>
        <div className="top-row">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              baselineShift: 'center',
            }}
          >
            <div className="go-back-button">
              <Backbutton primaryIcon={true} />
            </div>
            <div className="title">Verification</div>
            <div />
          </div>
          <div className="otp-code">
            <h2 className="primary-font">OTP code</h2>
            <p style={{ fontFamily: Fonts.secondary }}>
              We have sent the code verification to your <br /> mobile number{' '}
              <strong>{phoneNumber}</strong>
            </p>
          </div>

          <div className="text-center">
            <InputOtp
              isRequired
              size="lg"
              variant="faded"
              className="m-auto mt-0  pb-4"
              description="Code was sent via SMS"
              length={PHONE_OTP_CODE_LENGTH}
              value={otp}
              allowedKeys={
                '^\\d+$' // only allow numbers
              }
              onValueChange={handleOtpChange}
            />
          </div>
          <div className="text-center" />
          <div className="resend-otp">
            <button onClick={resendCode}>
              <Countdown
                key={currentTime}
                date={currentTime + targetedTime}
                renderer={CountdownTimer}
              />
            </button>
          </div>
        </div>
        <div className="bottom-row">
          <div className="form-submit">
            <Button
              onClick={() => {
                verifyOtp({});
              }}
              color="success"
              style={{ width: '100%' }}
            >
              Next
            </Button>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default PhoneLoginOTPScreen;

const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: auto;
  .pincode-input-container {
    justify-content: center;
    .pincode-input-text {
      padding: 0 !important;
      margin: 0 5px !important;
      border: 1px solid rgb(205, 205, 208);
      width: 45px !important;
      height: 45px !important;
      border-radius: 5px;
      background: rgba(241, 242, 243, 0.5) !important;
      font-family: ${Fonts.primary};
      font-weight: 500;
      font-size: 25px;
      line-height: 22px;
      text-align: center;
      color: #4c6870;
    }
  }
  .top-row {
    .title {
      padding: 25px 0;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
    }
    .otp-code {
      text-align: center;
      h2 {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      p {
        font-size: 17px;
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 50px;
      }
    }
    .resend-otp {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      button {
        font-size: 15px;
        font-weight: 500;
        color: #4c6870;
        border: none;
        background: transparent;
        padding-bottom: 3px;
        border-bottom: 1px solid #000000;
        cursor: pointer;
      }
    }
  }
  .bottom-row {
    text-align: center;
    position: absolute;
    bottom: 68px;
    width: 100%;
    position: static;
    margin-top: 50px;
  }
`;
