import { Link } from 'react-router-dom';
import { questionnaireSubmitted_path } from 'assets/index';
import { Wrapper } from '../../login_signup_switch/login_signup_switch.styled';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button } from '@nextui-org/react';

function SubmittedNewBusinessRequest() {
  return (
    <BackgroundContainer>
      <Wrapper>
        <div className=" d-flex justify-content-center inner-content m-4">
          <div className="col-lg-7 text-center content container">
            <img
              src={questionnaireSubmitted_path}
              alt="logo"
              style={{
                margin: 'auto',
                width: '300px',
              }}
            />{' '}
            {/* // TODO add illustration */}
            <h6
              style={{
                paddingTop: '20px',
              }}
            >
              Waiting for the information to be verified
            </h6>
            <p className="text-center" style={{ maxWidth: '600px' }}>
              Verification usually takes few days. We suggest you familiarize
              yourself with the instructions for working with the application.
            </p>
            <div
              className="d-flex flex-col"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link to="choose-business" replace={true}>
                <Button className="min-w-72" color="success">
                  Back To Business Selection
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default SubmittedNewBusinessRequest;
