import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { ConfigProvider } from 'antd';
import {
  ExpandListingInfoInProductsTableWrapper,
  Heading,
  TableStyled,
} from 'styles/style';
import {
  CategoryComponent,
  CategoryComponentWithImage,
} from './components/index';
import Nav from './components/products_header';
import {
  productPlaceholder_path,
  notFoundIllustration_path,
} from 'assets/index';
import {
  CURRENT_CURRENCY,
  LISTING_STATUS,
  LISTING_STATUS_COLORS,
  LISTING_STATUS_TYPES,
  LISTING_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  getExpandableTableBody,
  getExpandableTableHeaders,
  getMinMaxPrice,
} from './functions/products_functions';
import {
  ListingItem,
  Listing,
  SolrTemplateResponse,
  ListingBrief,
} from 'silal_app_base_react/src/data/types/listings';
import { getCurrentStore } from 'core/hooks/use_selector';
import ListingsRepository from 'data/repositories/listings_repository';
import { Category } from 'silal_app_base_react/src/data/types/categories';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { Tag } from 'antd';
import { ListingActionButtons } from 'silal_app_base_react/src/pages/listing_profile/components/action_buttons';
import Lightbox from 'react-image-lightbox';
import { ImageType } from 'silal_app_base_react/src/data/types/files';

const Products = () => {
  const [listings, setListings] = useState<Listing[]>();
  const [categories, setCategories] = useState<{
    [key: number]: {
      category: any;
      count: number;
      listings: (ListingBrief | Listing)[];
    };
  }>();
  const [loading, setLoading] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [publicListings, setPublicListings] = useState<Listing[]>();
  const [publicTemplates, setPublicTemplates] = useState<
    SolrTemplateResponse[]
  >([]);
  const currentStore = useSelector(getCurrentStore);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  const [image, setImage] = useState<ImageType | null>(null);
  const handleClose = () => {
    setImage(null);
  };

  useEffect(() => {
    setPublicListings(
      listings?.filter(
        (x) => x.listing_type_value === LISTING_TYPES.public_listing,
      ),
    );
  }, [listings]);

  useEffect(() => {
    async function fetchManagementTemplates() {
      await SolrRepository.searchManagementSilalOwnedTemplates({
        query: search,
        sortField: 'created_at_dt',
        sortOrder: 'desc',
      }).then((res: any) => {
        if (!res) return;
        setPublicTemplates(res);
      });
      setLoadingTemplates(false);
    }
    fetchManagementTemplates();
  }, [search]);

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'images',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => {
        const img_i = row.images.findIndex(
          (img) => img.id === row?.cover_image_id,
        );
        return row.images.length > 0 ? (
          <ShowImage
            image={row.images[img_i]}
            style={{ width: '100px', height: '100px', objectFit: 'scale-down' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setImage(row.images[img_i]);
            }}
          />
        ) : (
          <img src={productPlaceholder_path} alt="Placeholder" />
        );
      },
    },
    {
      title: 'Item Name',
      dataIndex: 'name_en',
      key: 'name_en',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <>
          #{row.id} {row.name_en} <br />
          {row.name_ar}
        </>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.name_en.localeCompare(b.name_en) ||
        a.name_ar.localeCompare(b.name_ar),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'id',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <>{categories && categories[row.category_id].category.name_en}</>
      ),
      sorter: (a: Listing, b: Listing) =>
        categories
          ? categories[a.category_id].category.name_en.localeCompare(
              categories[b.category_id].category.name_en,
            )
          : b.category_id - a.category_id,
    },
    {
      title: 'Variations',
      dataIndex: 'items',
      key: 'variations',
      align: 'center' as const,
      render: (items: ListingItem[]) => items.length,
    },
    {
      title: 'Date Created',
      dataIndex: 'date_created',
      key: 'date_created',
      align: 'center' as const,
      sorter: (a: Listing, b: Listing) =>
        moment(a.date_created).unix() - moment(b.date_created).unix(),
      render: (date: string) => formateServerDateToLocaleDate(date),
    },
    {
      title: 'Price',
      dataIndex: 'items',
      key: 'price',
      align: 'center' as const,
      render: (items: ListingItem[]) =>
        items.length > 0
          ? `${CURRENT_CURRENCY}${getMinMaxPrice(items)}`
          : `${CURRENT_CURRENCY}0`,
      sorter: (a: Listing, b: Listing) =>
        parseFloat(getMinMaxPrice(a.items).toString()) -
        parseFloat(getMinMaxPrice(b.items).toString()),
    },
    {
      title: 'Status',
      dataIndex: 'listing_status_num',
      key: 'status',
      align: 'center' as const,
      render: (status: number) => (
        <Tag
          color={
            LISTING_STATUS_COLORS[status as keyof typeof LISTING_STATUS_COLORS]
          }
          style={{ borderRadius: 5, color: '#fff' }}
        >
          {LISTING_STATUS_TYPES[status as keyof typeof LISTING_STATUS_TYPES]}
        </Tag>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.listing_status_num - b.listing_status_num,
    },
    {
      title: 'Actions',
      key: 'edit',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <ListingActionButtons
          showDeleteButton={true}
          showHideButton={true}
          showDetailsButton={true}
          listing={row}
          ListingsRepository={ListingsRepository}
          store_id={currentStore.id}
          onHideListingSuccess={(listingId: number) => {
            const newItems: Listing[] | undefined = publicListings?.map((x) => {
              if (x.id === listingId) {
                return {
                  ...x,
                  freezed_by_seller: !x.freezed_by_seller,
                  listing_status_num: LISTING_STATUS.changed,
                };
              }
              return x;
            });
            setPublicListings(newItems?.filter((i) => i));
          }}
        />
      ),
    },
  ];

  const isCategoryMatchSearch = (category: Category) => {
    if (!search) return true; // Include all categories if no search query
    const normalizedSearch = search.toLowerCase();
    return (
      category.name_en.toLowerCase().includes(normalizedSearch) ||
      category.name_ar.toLowerCase().includes(normalizedSearch)
    );
  };

  const filteredSearchCategoriesPredicate = (category: Category) => {
    if (search !== '') {
      return isCategoryMatchSearch(category);
    }
    return true; // Include all categories if no search query
  };

  const filteredItems = () => {
    if (search !== '') {
      const normalizedSearch = search.toLowerCase();

      return publicListings?.filter(
        (item) =>
          item.name_en.toLowerCase().includes(normalizedSearch) ||
          item.name_ar.toLowerCase().includes(normalizedSearch) ||
          item.id.toString().includes(normalizedSearch),
      );
    } else {
      return publicListings || []; // Return an empty array if publicListings is falsy
    }
  };

  // % PERF global state to save/cache products
  useEffect(() => {
    async function fetchSubCategories() {
      await ListingsRepository.paginateListingsDetails({
        store_id: currentStore.id,
        page: 1,
        per_page: 10000,
      }).then(async (res) => {
        if (!res) return;
        await ListingsRepository.getCategoryCountsOfListings(res!).then(
          (res) => {
            if (!res) return;
            setCategories(res);
          },
        );
        setListings(res!);
      });
      setLoading(false);
    }
    fetchSubCategories();
  }, []);

  const handleChangeInStockAndPrice = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        // TODO: fix any
        if (!response) return;
        if (response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems: Listing[] | undefined = publicListings?.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem) => dataItem.id === updatedItem.id,
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });
          setPublicListings(newItems?.filter((i) => i));
        }
      },
      failure: () => {},
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  return (
    <>
      {image && (
        <Lightbox
          mainSrc={(image as ImageType).public_s3_url}
          imageTitle={(image as ImageType).alt || 'No Alt Text'}
          mainSrcThumbnail={(image as ImageType).public_s3_url}
          onCloseRequest={handleClose}
        />
      )}
      <div>
        <Nav
          listings={listings}
          publicTemplates={publicTemplates}
          setSearch={setSearch}
        />
        <Row>
          <>
            {categories &&
              Object.keys(categories)
                // filter
                .filter((key) =>
                  filteredSearchCategoriesPredicate(
                    categories[key as any].category,
                  ),
                )
                .map((key, i) => (
                  <Col lg={3} md={4} sm={6} key={i}>
                    <CategoryComponentWithImage
                      link={`/products/${categories[key as any].category.id}/subcategory`}
                      categoryName={categories[key as any].category.name_en}
                      items_length={categories[key as any].count}
                      category_image={
                        categories[key as any].category.category_image
                      }
                    />
                  </Col>
                ))}
          </>
          {/* )} */}
        </Row>
        <Row className="pt-3">
          <Col lg={12} md={12} sm={12}>
            <Heading>General</Heading>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <CategoryComponent
              link="/products/templates"
              state={{
                publicTemplates: publicTemplates,
                listings: listings,
              }}
              categoryName="Templates"
              items_length={publicTemplates.length}
              className="grey"
              sub_categories_length={0} // TODO fix
            />
          </Col>
          <Col lg={3} md={4} sm={6}>
            <CategoryComponent
              link="/products/submitted"
              state={listings?.filter(
                (x) => x.listing_type_value === LISTING_TYPES.public_listing,
              )}
              categoryName="Submitted"
              items_length={
                listings?.filter(
                  (x) => x.listing_type_value === LISTING_TYPES.public_listing,
                ).length ?? 0
              }
              sub_categories_length={0} // TODO fix
              className="grey"
            />
          </Col>
        </Row>
        <Row className="pt-3" style={{ overflowX: 'auto' }}>
          {loading ? (
            <Spinner />
          ) : (
            <TableStyled>
              <h3 className="h3">All Products</h3>
              {filteredItems()?.length === 0 ? (
                <div className="no-orders">
                  <img src={notFoundIllustration_path} alt="no-orders" />
                  <h3>No products were found</h3>
                </div>
              ) : (
                <Table
                  size="middle"
                  columns={columns}
                  dataSource={filteredItems()}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 50,
                    showSizeChanger: true,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['50', '100', '500', '1000', '2000'],
                  }}
                  expandable={{
                    expandRowByClick: true,
                    expandedRowRender: (record) => (
                      <ExpandedComponent
                        data={record}
                        handleChangeInStockAndPrice={
                          handleChangeInStockAndPrice
                        }
                      />
                    ),
                  }}
                />
              )}
            </TableStyled>
          )}
        </Row>
      </div>
    </>
  );
};

export const ExpandedComponent = ({
  data,
  handleChangeInStockAndPrice,
}: {
  data: Listing;
  handleChangeInStockAndPrice: (item: ListingItem, data: Listing) => void;
}) => {
  const currentImages = data?.items.map((item) => item.image).flat();
  const [index, setIndex] = useState(-1);
  const image = currentImages?.[index];
  const currentImage = currentImages?.[index];
  const nextIndex = (index + 1) % currentImages?.length;
  const nextImage = currentImages?.[nextIndex] || currentImage;
  const prevIndex = (index + currentImages?.length - 1) % currentImages?.length;
  const prevImage = currentImages?.[prevIndex] || currentImage;
  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => {
    setIndex(-1);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  return (
    <>
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={`Image #${currentImages[index].id} for item #${data.items[index].id}`}
          mainSrcThumbnail={image.public_s3_url}
          nextSrc={nextImage.public_s3_url}
          nextSrcThumbnail={nextImage.public_s3_url}
          prevSrc={prevImage.public_s3_url}
          prevSrcThumbnail={prevImage.public_s3_url}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      <ExpandListingInfoInProductsTableWrapper key={data.id}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#05AE4B',
                colorTextHeading: '#fff',
              },
            },
          }}
        >
          <Table
            columns={getExpandableTableHeaders(data)}
            dataSource={getExpandableTableBody(
              data,
              handleChangeInStockAndPrice,
              handleClick,
            )}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </ConfigProvider>
      </ExpandListingInfoInProductsTableWrapper>
    </>
  );
};

export default Products;
