import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actionGetManagedStores } from 'store/store/store_actions';
import { actionGetSetUserProfile } from 'store/user/user_actions';
import {
  isUserSemiAuthenticated,
  fullLogout,
  isUserAuhtorized,
  isUserFullyAuthenticated,
} from 'routes/functions/routes_functions';
import {
  getCurrentStoreState,
  getCurrentUserState,
} from 'core/hooks/use_selector';

export function AuhtneticationPagesHandler({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();

  const userState = useSelector(getCurrentUserState);
  const { user, token } = userState;
  const { stores, currentStore } = useSelector(getCurrentStoreState);
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      failure: (error: any) => {
        // TODO fix any
        if (error?.code === 401) {
          fullLogout(dispatch, navigate);
        }
      },
    };
    if (isUserSemiAuthenticated(userState)) {
      dispatch(actionGetManagedStores());
      dispatch(actionGetSetUserProfile(payload));
    }
  }, [token]);

  useEffect(() => {
    if (isUserFullyAuthenticated({ userState, currentStore })) {
      navigate('/');
      return;
    } else if (isUserSemiAuthenticated(userState)) {
      if (window.location.pathname === '/questionaire-form') {
        navigate('/questionaire-form');
        return;
      }
      if (stores?.length > 0) {
        navigate('/choose-business');
        return;
      } else {
        // navigate('/new-business');
        navigate('/choose-business');
        return;
      }
    } else if (isUserAuhtorized(userState)) {
      if (!user?.first_name || !user?.last_name || !user?.id_number) {
        if (!user?.verified_email) {
          navigate('/signup-email');
          return;
        }
        if (user?.verified_email && !user?.has_password) {
          navigate('/create-password');
          return;
        }
        navigate('/seller-info');
        return;
      }
    }
  }, [user, stores]);

  return <div>{children}</div>;
}
