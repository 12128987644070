import { useEffect, useState, Fragment } from 'react';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import WorkingHoursDaysRow from './working_hours';
import Error from 'silal_app_base_react/src/components/error';
import FormFooter from './form_footer';
import { weekDays } from 'core/constants/working_hours';
import { StoreSignUpForm } from 'data/types/store';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import { DropDownOption } from 'data/types/general';
import { toast } from 'react-toastify';
import { Input, Textarea } from '@nextui-org/react';
import { Select, SelectItem } from '@nextui-org/react';
import React from 'react';
import { CheckboxGroup, Checkbox, RadioGroup, Radio } from '@nextui-org/react';

type Form3Props = {
  setCurrentStep: (step: number) => void;
  step: number;
  handleUserInput: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  formData: StoreSignUpForm;
  setFormData: (formData: StoreSignUpForm) => void;
  createStore: () => void;
  handleWorkingTimes: any;
};

function Form3({
  setCurrentStep,
  step,
  handleUserInput,
  formData,
  setFormData,
  createStore,
  handleWorkingTimes,
}: Form3Props) {
  const [enabledWorkDaysCount, setEnabledWorkDaysCount] = useState(7);
  const [value, setValue] = React.useState(new Set([]));
  const [isReady, setIsReady] = useState(false); // Track readiness of data

  const [errors, setErrors] = useState({
    is_home_business: '',
    slogan: '',
    main_category_id: '',
    bio: '',
    paymentMethods: '',
    workingTimes: '',
  });

  const [formattedCategories, setFormattedCategories] = useState<
    DropDownOption[]
  >([]);

  useEffect(() => {
    async function fetchCategories() {
      await SharedRepository.getCategoryBrief(0).then((res) => {
        // if the type of res isnt boolean
        if (!res) return;
        const formatCategories = res?.sub_categories?.map((category) => {
          return {
            label: category.name_en + ' | ' + category.name_ar,
            value: category.id,
          };
        });
        setFormattedCategories(formatCategories || []);
        setIsReady(true);
      });
    }
    fetchCategories();
  }, []);

  const handleValidations = () => {
    const errorsFields = {
      is_home_business: '',
      slogan: '',
      main_category_id: '',
      bio: '',
      paymentMethods: '',
      workingTimes: '',
    };
    let error = false;
    Object.keys(formData).map(function (keyName) {
      const value = formData[keyName as keyof StoreSignUpForm];
      if (keyName === 'slogan') {
        if ((value as string)?.length > 40) {
          errorsFields.slogan = 'Slogan must be less than 40 characters';
          error = true;
        }
      } else if (
        !formData.accepts_cash_payments &&
        !formData.accepts_credit_card_payments &&
        !formData.accepts_mobile_payments &&
        !formData.accepts_cheques_payments
      ) {
        errorsFields.paymentMethods =
          'ⓘ At least one payment method is required.';
        error = true;
      } else if (enabledWorkDaysCount < 1) {
        errorsFields.workingTimes = 'ⓘ At least one working day is required.';
        error = true;
      } else if (keyName === 'main_category_id') {
        if (!value) {
          errorsFields.main_category_id = 'ⓘ Catergory is required.';
          error = true;
        }
      } else if (keyName === 'bio') {
        if ((value as string)?.length > 256) {
          errorsFields.bio =
            'ⓘ Store description must be less than 256 characters';

          error = true;
        }
      }
      return null;
    });
    if (error) {
      toast.warn('Please check the form for errors.');
    }
    setErrors(errorsFields);
    return error;
  };

  const handelNext = () => {
    console.log('Before validation, main_category_id:', formData);
    if (!handleValidations()) {
      createStore();
    }
  };

  const handlePaymentMethods = (type: string, value: boolean) => {
    if (value) errors.paymentMethods = '';
    setFormData({ ...formData, [type]: value });
  };

  const handleSelectionChange = (key: string) => {
    console.log('Processed key in handleSelectionChange:', key); // Log processed key
    setFormData({ ...formData, main_category_id: key.toString() });
  };

  return (
    <>
      <div className="justify-content-center p-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 g-3 mt-4">
            <div className="category col-12 d-flex justify-content">
              <RadioGroup
                label="Select Your Business Type"
                defaultValue="true"
                value={formData.is_home_business ? 'true' : 'false'}
                isRequired={true}
                orientation="vertical"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    is_home_business: event.target.value === 'true',
                  });
                }}
                description={
                  errors.is_home_business ? (
                    <span style={{ color: 'red' }}>
                      {errors.is_home_business}
                    </span>
                  ) : null
                }
              >
                <Radio value="false">
                  <div className="flex flex-col">
                    <span>Store</span>
                    <p className="text-small text-default">
                      Contact & address info will be visible to customers.
                    </p>
                  </div>
                </Radio>
                <Radio value="true">
                  <div className="flex flex-col">
                    <span>Home business</span>
                    <p className="text-small text-default">
                      Contact & address info will be hidden from customers.
                    </p>
                  </div>
                </Radio>
              </RadioGroup>
            </div>
          </div>
          <hr className="m-3"></hr>
          <div className="col-12 col-md-12 g-3">
            {/* Store Category Section */}
            <div>
              {isReady && (
                <Select
                  isRequired
                  label="Store Category"
                  placeholder="Select store category"
                  isInvalid={!!errors.main_category_id}
                  errorMessage={errors.main_category_id}
                  color={errors.main_category_id ? 'danger' : 'success'}
                  variant="bordered"
                  selectedKeys={
                    new Set([formData.main_category_id?.toString()])
                  }
                  onSelectionChange={(keys) => {
                    // Convert the Set to an array and extract the first key
                    const selectedKey = Array.from(keys)[0] as string;
                    console.log('Selected value:', selectedKey);
                    setFormData({
                      ...formData,
                      main_category_id: selectedKey, // Update the formData state
                    });
                  }}
                  name="main_category_id"
                >
                  {formattedCategories?.map((category) => (
                    <SelectItem
                      key={category.value.toString()}
                      value={category.value.toString()}
                    >
                      {category.label}
                    </SelectItem>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <hr className="m-3"></hr>

          <div className="col-12 col-md-12 g-3">
            {/* Accepted Payment Methods Section */}
            <h6 className="title">
              Accepted payment methods{' '}
              <span className="asterik " style={{ color: 'red' }}>
                *
              </span>
            </h6>

            <CheckboxGroup
              value={[
                formData.accepts_cash_payments ? 'cash' : '',
                formData.accepts_credit_card_payments ? 'credit-card' : '',
                formData.accepts_mobile_payments ? 'mobile' : '',
                formData.accepts_cheques_payments ? 'cheques' : '',
              ]}
              onChange={(selectedValues) => {
                setFormData({
                  ...formData,
                  accepts_cash_payments: selectedValues.includes('cash'),
                  accepts_credit_card_payments:
                    selectedValues.includes('credit-card'),
                  accepts_mobile_payments: selectedValues.includes('mobile'),
                  accepts_cheques_payments: selectedValues.includes('cheques'),
                });
              }}
              aria-label="Payment Methods"
            >
              <div className="row d-flex justify-content-between">
                <div className="col-lg-6 my-1">
                  <Checkbox
                    value="cash"
                    isSelected={formData.accepts_cash_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_cash_payments',
                        e.target.checked,
                      )
                    }
                    color="success"
                    aria-label="Cash Payment"
                  >
                    Cash Payment
                  </Checkbox>
                </div>

                <div className="col-lg-6 my-1">
                  <Checkbox
                    value="credit-card"
                    isSelected={formData.accepts_credit_card_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_credit_card_payments',
                        e.target.checked,
                      )
                    }
                    color="success"
                    aria-label="Credit Card Payment"
                  >
                    Credit Card Payment
                  </Checkbox>
                </div>

                <div className="col-lg-6 my-1">
                  <Checkbox
                    value="mobile"
                    isSelected={formData.accepts_mobile_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_mobile_payments',
                        e.target.checked,
                      )
                    }
                    color="success"
                    aria-label="Mobile Payment (Apple Pay / Google Pay)"
                  >
                    Mobile Payment (Apple Pay / Google Pay)
                  </Checkbox>
                </div>

                <div className="col-lg-6 my-1">
                  <Checkbox
                    value="cheques"
                    isSelected={formData.accepts_cheques_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_cheques_payments',
                        e.target.checked,
                      )
                    }
                    color="success"
                    aria-label="Cheques"
                  >
                    Cheques
                  </Checkbox>
                </div>
              </div>
            </CheckboxGroup>

            {errors.paymentMethods ? (
              <Error text={errors.paymentMethods} />
            ) : (
              ' '
            )}
          </div>
          <hr className="m-3"></hr>

          {/* Store Slogan Section */}
          <div className="slogan col-12 col-md-12 g-3">
            <Input
              label="Store Slogan"
              name="slogan"
              onChange={handleUserInput}
              type="text"
              value={formData.slogan}
              placeholder="What’s a slogan that describes your business?"
              aria-label="Store slogan"
              variant="bordered"
              fullWidth
            />
          </div>

          {/* Store Description Section */}
          <div className="slogan col-12 col-md-12 g-3">
            <Textarea
              value={formData.bio}
              label="Store description"
              placeholder="Briefly describe what service / product you are posting. This information will be visible on your business profile and available to customers. Max 180 characters."
              aria-label="Bio"
              rows={5}
              name="bio"
              onChange={handleUserInput}
              variant="bordered"
              maxLength={180} // Optional: Limit to 180 characters
              fullWidth
            />
          </div>
          <hr className="m-3"></hr>

          <div className="col-12 col-md-12 g-3">
            {/* Working Hours Section */}
            <h6 className="title">Working hours</h6>

            <div className="d-flex flex-column">
              {weekDays.map((dayWithKeys) => {
                return (
                  <Fragment key={dayWithKeys.name_en}>
                    <WorkingHoursDaysRow
                      setEnabledWorkDaysCount={setEnabledWorkDaysCount}
                      enabledWorkDaysCount={enabledWorkDaysCount}
                      day={dayWithKeys}
                      formData={formData}
                      setFormData={setFormData}
                      handleWorkingTimes={handleWorkingTimes}
                    />
                  </Fragment>
                );
              })}
            </div>
            {errors.workingTimes ? <Error text={errors.workingTimes} /> : ' '}
          </div>
        </div>
      </div>

      <FormFooter
        currentStep={step}
        onClickNext={handelNext}
        onClickBack={() => setCurrentStep(step - 1)}
      />
    </>
  );
}

export default Form3;

export const Form3Styled = styled.div`
  font-family: ${Fonts.secondary};
  font-style: normal;
  color: #002733;
  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(5, 174, 75, 0.25) !important;
  }
  .card1,
  .card2,
  .card3 {
    padding: 0px 10px 10px 10px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .img {
    background-image: url('src/assets/images/svg/ribbon.svg');
    background-repeat: no-repeat;
    background-position: top right;
  }
  .light {
    background-color: #cdefdb;
    border-radius: 5px;
    padding: 5px;
    padding: 13px;
    border: 1px solid rgb(5, 174, 75);
  }
  .dull {
    background-color: rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: 5px;
    position: relative;
    padding: 13px;
  }

  .light:hover {
    border: 1px solid #05ae4b;
  }
  .dull:hover {
    border: 1px solid #cdcdd0;
  }

  h6 {
    padding-top: 19px;
    /* padding-top: 25px; */
    font-weight: 500;
    font-size: 15px;
    color: #002733;
  }
  .asterik {
    color: red;
  }
  .CheckBox {
    margin-top: -9px;
  }
  .one {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 13px 0px 13px 28px;
    background: white;
  }
  .padd {
    padding-bottom: 7px;
  }
  .form-check-input:checked {
    background-color: #05ae4b;
  }
  .form-check-label {
    font-weight: 500;
    font-size: 17px;
  }
  .slogan {
    padding-top: 20px;
  }
  .area {
    width: 100%;
    padding: 8px;
  }
`;
