import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ShowImage from 'silal_app_base_react/src/components/show_image';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { Heading, TableStyled } from 'styles/style';
import { CategoryComponent } from '../components/index';
import Nav from '../components/products_header';
import {
  notFoundIllustration_path,
  productPlaceholder_path,
} from 'assets/index';
import {
  CURRENT_CURRENCY,
  LISTING_STATUS,
  LISTING_STATUS_COLORS,
  LISTING_STATUS_TYPES,
  LISTING_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  ListingItem,
  Listing,
  SolrTemplateResponse,
} from 'silal_app_base_react/src/data/types/listings';
import { ExpandedComponent } from '../products_page';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import { getCurrentStoreState } from 'core/hooks/use_selector';
import {
  fetchCategoryListings,
  getMinMaxPrice,
} from '../functions/products_functions';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { RootState } from 'data/types/state';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { ListingActionButtons } from 'silal_app_base_react/src/pages/listing_profile/components/action_buttons';
import ListingsRepository from 'data/repositories/listings_repository';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import Lightbox from 'react-image-lightbox';

const SubcategoryScreen = () => {
  const { categoryId } = useParams();

  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );

  const [image, setImage] = useState<ImageType | null>(null);
  const handleClose = () => {
    setImage(null);
  };

  const { currentStore } = useSelector(getCurrentStoreState);
  const [listings, setListings] = useState<Listing[]>([]);
  const [publicTemplates, setPublicTemplates] = useState<
    SolrTemplateResponse[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!minimalCategoriesList) dispatch(actionGetCategoriesMinimalList());
  }, []);

  useEffect(() => {
    async function fetchManagementTemplates() {
      await SolrRepository.searchManagementSilalOwnedTemplates({
        query: search,
      }).then((res: any) => {
        if (!res) return;
        const resX = res
          .filter((x: any) => x.category_id.toString() === categoryId)
          .map((x: any) => {
            return {
              ...x,
              listing_type_value: LISTING_TYPES.template,
            };
          });
        setLoading(false);
        setPublicTemplates(resX);
      });
    }
    fetchCategoryListings(setListings, currentStore.id, categoryId, setLoading);

    fetchManagementTemplates();
  }, [categoryId, currentStore]);

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'images',
      key: 'photo',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => {
        const img_i = row.images.findIndex(
          (img) => img.id === row?.cover_image_id,
        );
        return row.images.length > 0 ? (
          <ShowImage
            image={row.images[img_i]}
            style={{ width: '100px', height: '100px', objectFit: 'scale-down' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setImage(row.images[img_i]);
            }}
          />
        ) : (
          <img src={productPlaceholder_path} alt="Placeholder" />
        );
      },
    },
    {
      title: 'Item Name',
      dataIndex: 'name_en',
      key: 'name',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <>
          #{row.id} {row.name_en} <br />
          {row.name_ar}
        </>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.name_en.localeCompare(b.name_en) ||
        a.name_ar.localeCompare(b.name_ar),
    },
    {
      title: 'Variations',
      dataIndex: 'items',
      key: 'variations',
      align: 'center' as const,
      render: (items: ListingItem[]) => items.length,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'id',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <>
          {minimalCategoriesList &&
            minimalCategoriesList.filter((x) => x.id === row.category_id)[0]
              .name_en}
        </>
      ),
      sorter: (a: Listing, b: Listing) =>
        minimalCategoriesList
          ? minimalCategoriesList[a.category_id].name_en.localeCompare(
              minimalCategoriesList[b.category_id].name_en,
            )
          : b.category_id - a.category_id,
    },
    {
      title: 'Last Purchase',
      dataIndex: 'last_purchase_date',
      key: 'lastPurchase',
      align: 'center' as const,
      render: (date: string) => formateServerDateToLocaleDate(date),
    },
    {
      title: 'Price',
      dataIndex: 'items',
      key: 'price',
      align: 'center' as const,
      render: (items: ListingItem[]) =>
        items.length > 0
          ? `${CURRENT_CURRENCY}${getMinMaxPrice(items)}`
          : `${CURRENT_CURRENCY}0`,
      sorter: (a: Listing, b: Listing) =>
        parseFloat(getMinMaxPrice(a.items).toString()) -
        parseFloat(getMinMaxPrice(b.items).toString()),
    },
    {
      title: 'Status',
      dataIndex: 'listing_status_num',
      key: 'status',
      align: 'center' as const,
      render: (status: number) => (
        <span
          style={{
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: '5px',
            color: 'white',
            backgroundColor:
              LISTING_STATUS_COLORS[
                status as keyof typeof LISTING_STATUS_COLORS
              ],
          }}
        >
          {LISTING_STATUS_TYPES[status as keyof typeof LISTING_STATUS_TYPES]}
        </span>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.listing_status_num - b.listing_status_num,
    },
    {
      title: 'Actions',
      key: 'edit',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <ListingActionButtons
          showDeleteButton={true}
          showHideButton={true}
          showDetailsButton={true}
          listing={row}
          ListingsRepository={ListingsRepository}
          store_id={currentStore.id}
          onHideListingSuccess={(listingId: number) => {
            const newItems: Listing[] | undefined = listings?.map((x) => {
              if (x.id === listingId) {
                return {
                  ...x,
                  freezed_by_seller: !x.freezed_by_seller,
                  listing_status_num: LISTING_STATUS.changed,
                };
              }
              return x;
            });
            setListings(newItems?.filter((i) => i));
          }}
        />
      ),
    },
  ];

  const handleChangeInStockAndPrice = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        if (!response) return;
        if (response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems: Listing[] | undefined = listings?.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem) => dataItem.id === updatedItem.id,
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });
          setListings(newItems?.filter((i) => i));
        }
      },
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  const filteredItems = () => {
    const normalizedSearch = search.toLowerCase();

    if (search !== '') {
      return listings.filter(
        (item) =>
          item.name_en.toLowerCase().includes(normalizedSearch) ||
          item.name_ar.toLowerCase().includes(normalizedSearch),
      );
    }

    return listings;
  };

  return (
    <>
      {image && (
        <Lightbox
          mainSrc={(image as ImageType).public_s3_url}
          imageTitle={(image as ImageType).alt || 'No Alt Text'}
          mainSrcThumbnail={(image as ImageType).public_s3_url}
          onCloseRequest={handleClose}
        />
      )}
      <Nav
        listings={listings}
        publicTemplates={publicTemplates}
        subcategory={
          minimalCategoriesList.find((x) => x.id === parseInt(categoryId!))
            ?.name_en
        }
        setSearch={setSearch}
      />
      <Row>
        <Row className="pt-3">
          <Col lg={12} md={12} sm={12}>
            <Heading>General</Heading>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <CategoryComponent
              link={`/products/${categoryId}/templates`}
              state={{
                publicTemplates: publicTemplates.filter(
                  (x) => x.category_id === parseInt(categoryId!),
                ),
                listings: listings.filter(
                  (x) => x.category_id === parseInt(categoryId!),
                ),
              }}
              categoryName="Templates"
              items_length={publicTemplates?.length}
              className="grey"
            />
          </Col>
          <Col lg={3} md={4} sm={6}>
            <CategoryComponent
              link={`/products/${categoryId}/submitted`}
              state={listings?.filter(
                (x) =>
                  x.listing_type_value === LISTING_TYPES.public_listing &&
                  x.category_id === parseInt(categoryId!),
              )}
              categoryName="Submitted"
              items_length={
                listings?.filter(
                  (x) => x.listing_type_value === LISTING_TYPES.public_listing,
                )?.length ?? 0
              }
              className="grey"
            />
          </Col>
        </Row>
        {loading ? (
          <Spinner />
        ) : (
          <TableStyled>
            <h3 className="h3">Category Products</h3>
            {filteredItems()?.length === 0 ? (
              <div className="no-orders">
                {loading ? (
                  <>
                    <Spinner />
                    <h3>Loading from server...</h3>
                  </>
                ) : (
                  <>
                    <img src={notFoundIllustration_path} alt="no-orders" />
                    <h3>No products were found</h3>
                  </>
                )}
              </div>
            ) : (
              <Table
                size="middle"
                columns={columns}
                dataSource={filteredItems()}
                rowKey="id"
                pagination={{
                  defaultPageSize: 50,
                  showSizeChanger: true,
                  position: ['topRight', 'bottomRight'],
                  pageSizeOptions: ['50', '100', '500', '1000', '2000'],
                }}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <ExpandedComponent
                      data={record}
                      handleChangeInStockAndPrice={handleChangeInStockAndPrice}
                    />
                  ),
                }}
              />
            )}
          </TableStyled>
        )}
      </Row>
    </>
  );
};

export default SubcategoryScreen;
