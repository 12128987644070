import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Wrapper from './no_business_available_screen.styled';
import { logoutIcon_path, notFoundGhostIllustration_path } from 'assets/index';
import { fullLogout } from 'routes/functions/routes_functions';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { Button } from '@nextui-org/react';
import { Colors } from 'silal_app_base_react/src/config/theme';

function NoBusinessAvailableSplash() {
  const { user } = useSelector(getCurrentUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    fullLogout(dispatch, navigate);
  };

  return (
    <>
      <Wrapper>
        <div className="d-flex justify-content-end">
          <button className="m-3 logout-btn" onClick={handleLogout}>
            <img className="p-1" src={logoutIcon_path} alt="logout-btn" />
            Logout
          </button>
        </div>
        <div
          className=" d-flex justify-content-center"
          style={{
            backgroundColor: 'var(--app-background-color)',
          }}
        >
          <div className="col-lg-4 text-center">
            {/* // TODO add illustration */}
            <NoBusinessesFoundIllustration />
            <p
              className="mb-2"
              style={{
                fontSize: '1rem',
              }}
            >
              Your Merchant ID for invites is:
              <span className="separate"> #{user.id}</span>
            </p>
            <div>
              <Button
                onPress={() => navigate('/questionaire-splash')}
                color="success"
                className="w-full mt-b h-11"
              >
                Create my own business
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export const NoBusinessesFoundIllustration = () => {
  return (
    <div className="d-flex flex-column align-items-center max-w-[500px] mx-auto">
      <img
        src={notFoundGhostIllustration_path}
        alt="logo"
        style={{
          width: '250px',
          margin: 'auto',
        }}
      />{' '}
      <h6>No businesses were found!</h6>
      <p
        style={{
          fontSize: '1rem',
          lineHeight: '1.5rem',
          textAlign: 'center',
          marginBottom: '1rem',
          color: Colors.alternateText,
        }}
      >
        You don’t have any businesses, either create your own or share your
        <span> Merchant ID </span>
        with a business owner to be added to their business
      </p>
    </div>
  );
};

export default NoBusinessAvailableSplash;
