import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { UserData } from 'data/types/state';
import {
  API_BASE,
  AUTH_API_BASE,
} from 'silal_app_base_react/src/config/constants';
import { SELLER_MESSAGES } from 'data/constants/messages';
class SellerApiRepository extends AuthenticatedApiRepository {
  private static instance: SellerApiRepository | null = null;
  private constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): SellerApiRepository {
    if (!SellerApiRepository.instance) {
      SellerApiRepository.instance = new SellerApiRepository(axiosInstance);
    }
    return SellerApiRepository.instance;
  }

  // ? Revoke (Universal Logout)
  universalLogout = async () => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', '/universal_logout/', {
          baseApiUrl: AUTH_API_BASE,
        }),
        {
          customSuccessMsg: SELLER_MESSAGES.UniversalLogout,
          noFailureMsg: false,
          noSuccessMsg: false,
        },
      );
      return res.status === 204;
    } catch (e: any) {
      return false;
    }
  };

  getUserInformation = async () => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', '/personal_info/'),
        {
          noSuccessMsg: true,
          noPendingMsg: true,
          noFailureMsg: true,
        },
      );
      return res.data.user_data as UserData;
    } catch (e) {
      return false;
    }
  };

  addEmail = async (email: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/preferences/email', {
          baseApiUrl: AUTH_API_BASE,
          mustEndWithSlash: true,
          data: {
            email: email,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
          noPendingMsg: true,
        },
      );

      return res.status === 202 || res.status === 304;
    } catch (e: any) {
      return false;
    }
  };

  deleteEmail = async () => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', '/preferences/email/', {
          baseApiUrl: AUTH_API_BASE,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: SELLER_MESSAGES.EmailDeleted,
        },
      );
      return res.status === 204;
    } catch (e) {
      return false;
    }
  };

  addPassword = async (password: string, password2: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/preferences/password', {
          baseApiUrl: AUTH_API_BASE,
          mustEndWithSlash: true,
          data: {
            password: password,
            password2: password2,
          },
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: true,
          noPendingMsg: true,
        },
      );
      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };

  addPersonalInfo = async (
    first_name: string,
    last_name: string,
    id_number: number,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/personal_info', {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
          data: {
            first_name: first_name,
            last_name: last_name,
            id_number: id_number,
          },
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: true,
          noPendingMsg: true,
        },
      );

      return res.data.user as UserData;
    } catch (e: any) {
      return false;
    }
  };

  deleteUserData = async (): Promise<boolean> => {
    try {
      // Ensure that the code is running in the browser (client-side)
      if (typeof window === 'undefined' || !window.localStorage) {
        throw new Error('localStorage is not available in this environment');
      }

      // Authenticate first to get the token
      const loginResponse = await this.sendRequest('POST', '/login/phone/otp', {
        baseApiUrl: AUTH_API_BASE,
        data: {
          // Include login data (e.g., phone number or credentials)
        },
      });

      // Extract bearer token from the login response
      const token = loginResponse?.data?.bearer;
      if (!token) {
        throw new Error('Authentication failed. No token received.');
      }

      // Check if the token is available in localStorage (if you are using localStorage to store the token)
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem('token', token); // Save token in localStorage
      }

      // Perform the delete request with the bearer token
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', '/delete-my-data', {
          baseApiUrl: API_BASE,
          additionalHeaders: { Authorization: `Bearer ${token}` },
        }),
        {
          customSuccessMsg: SELLER_MESSAGES.MerchantDeleted,
          noFailureMsg: false,
          noSuccessMsg: false,
        },
      );
      return res.status === 204;
    } catch (e: any) {
      console.error('Error during delete operation:', e.message);
      return false;
    }
  };
}

const SellerRepository = SellerApiRepository.getInstance(sellerAxiosInstance);
export default SellerRepository;
