import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  page404Illustration_path,
  silal_green_secondary_logo_plcaeholder,
} from 'assets/index';
import Button from 'silal_app_base_react/src/components/buttons';

export function NotFoundPage({ path = '/404' }) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(path); // Redirect to the 404 route
    }, 500);
  }, []);

  return <div>Page not found, routing...</div>;
}

export const Page404 = ({
  time = 10,
  path = '/',
}: {
  time?: number;
  path?: string;
}) => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(time);

  useEffect(() => {
    // Start the countdown timer
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    // Redirect after the specified delay
    const timeout = setTimeout(() => {
      clearInterval(timer); // Stop the interval
      navigate(path); // Redirect
    }, time * 1000);

    // Cleanup on unmount or re-render
    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, [navigate, time, path]);

  return (
    <Page404Container>
      <BackgroundShapes>
        {/* Create multiple animated shapes */}{' '}
        <AnimatedImage
          src={silal_green_secondary_logo_plcaeholder}
          style={{ top: '80%', left: '20%', width: '120px', height: '120px' }}
        />
        <AnimatedImage
          src={silal_green_secondary_logo_plcaeholder}
          alt="Ghost"
          style={{ top: '20%', left: '10%', width: '100px', height: '100px' }}
        />
        <AnimatedImage
          src={silal_green_secondary_logo_plcaeholder}
          style={{ top: '50%', left: '30%', width: '80px', height: '80px' }}
        />
        <AnimatedImage
          src={silal_green_secondary_logo_plcaeholder}
          style={{ top: '70%', left: '70%', width: '120px', height: '120px' }}
        />{' '}
        <AnimatedImage
          src={silal_green_secondary_logo_plcaeholder}
          style={{ top: '10%', left: '70%', width: '120px', height: '120px' }}
        />
        <AnimatedImage
          src={silal_green_secondary_logo_plcaeholder}
          style={{ top: '20%', left: '90%', width: '120px', height: '120px' }}
        />
      </BackgroundShapes>
      <ContentWrapper>
        <Heading>Oops! Page Not Found</Heading>
        <Paragraph>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable. Redirecting to the home page
          in {counter} seconds...
        </Paragraph>
        <GhostImage src={page404Illustration_path} alt="Ghost" />
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate('/');
            }}
          >
            Go to Home
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Page404Container>
  );
};

const Page404Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7; /* Choose your background color */
  position: relative;
  overflow: hidden;
`;

const BackgroundShapes = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const moveShape = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(50px, -50px);
  }
  50% {
    transform: translate(-50px, 50px);
  }
  75% {
    transform: translate(50px, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const AnimatedImage = styled.img`
  position: absolute;
  animation: ${moveShape} 10s linear infinite;
  transform-origin: center;
`;

const ContentWrapper = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #333; /* Choose your text color */
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  padding: 0 1rem;
  color: #555; /* Choose your text color */
`;

const GhostImage = styled.img`
  max-width: 40%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
