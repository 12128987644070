import { useState } from 'react';
import CountrySelectForm from 'components/select_country_form';
import FormFooter from './form_footer';
import { StoreSignUpForm } from 'data/types/store';
import { CurrentContries } from 'core/constants/constants';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { Country } from 'data/types/general';
import { FILL_SIGNUP_FORM, OVERRIDE_FOR_DEBUG } from 'core/debug/debug';
import { SIGNUP_FORM_DEBUG_DATA } from 'core/debug/signup_form.data';
import { toast } from 'react-toastify';
import { CURRENT_VAT } from 'silal_app_base_react/src/config/constants';
import { BusinessTaxType } from 'silal_app_base_react/src/data/types/stores';
import { Input, RadioGroup, Radio, Spacer } from '@nextui-org/react';

type Form1Props = {
  step: number;
  setCurrentStep: (step: number) => void;
  setModalState: (state: boolean) => void;
  handleUserInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: StoreSignUpForm;
  setSelectedCountry: (country: Country) => void;
};

function Form1({
  step,
  setCurrentStep,
  setModalState,
  handleUserInput,
  formData,
  setSelectedCountry,
}: Form1Props) {
  const [errors, setErrors] = useState({
    name_en: '',
    name_ar: '',
    business_owner_full_name: '',
    countryCode: '',
    business_phone: '',
    business_email: '',
    business_website_url: '',
    business_social_media_url: '',
    tax_id: '',
    tax_type: '',
    bank_number: '',
    bank_branch_number: '',
    bank_account_number: '',
  });

  const hasValidationErrors = () => {
    const errorsFields = {
      name_en: '',
      name_ar: '',
      business_owner_full_name: '',
      countryCode: '972',
      business_phone: '',
      business_email: '',
      business_website_url: '',
      business_social_media_url: '',
      tax_id: '',
      tax_type: '',
      bank_number: '',
      bank_branch_number: '',
      bank_account_number: '',
    };
    let error = false;

    Object.keys(formData).map(function (keyName) {
      const value =
        OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
          ? (SIGNUP_FORM_DEBUG_DATA as any)[keyName]
          : formData[keyName as keyof StoreSignUpForm];

      if (keyName === 'name_en') {
        if (!value) {
          errorsFields.name_en = 'ⓘ Business name is required.';
          error = true;
        }
        if (
          !FormValidators.isEnglish(value as string) ||
          (value as string).length > 64
        ) {
          errorsFields.name_en =
            'ⓘ Business name must be in English and less than 64 charachters.';

          error = true;
        }
      } else if (keyName === 'name_ar') {
        if (
          !FormValidators.isArabic(value as string) ||
          (value as string).length > 64
        ) {
          errorsFields.name_ar =
            'ⓘ Business name in Arabic must be in Arabic and less than 64 charachters.';
          error = true;
        }
        if (!value) {
          errorsFields.name_ar = 'ⓘ Business name in Arabic is required.';
          error = true;
        }
      } else if (keyName === 'business_owner_full_name') {
        if (!value) {
          errorsFields.business_owner_full_name =
            'ⓘ Business owner name is required.';
        }

        if ((value as string).length > 32) {
          errorsFields.business_owner_full_name =
            'ⓘ Business owner name must be less then 32 characters.';

          error = true;
        }
      } else if (keyName === 'business_phone') {
        if (!value) {
          errorsFields.business_phone = 'ⓘ Phone number is required.';
          error = true;
        }
        if (!FormValidators.isPhoneNumber(value as string)) {
          errorsFields.business_phone =
            'ⓘ Phone number must be 9-10 digits, e.g: 0523456789 or 027654321';

          error = true;
        }
      } else if (keyName === 'business_email') {
        if (!value) {
          errorsFields.business_email = 'ⓘ Business email is required.';
          error = true;
        } else if (!FormValidators.isEmail(value as string)) {
          errorsFields.business_email =
            'ⓘ Email is Incorrect, e.g: example@example.com';
          error = true;
        }
      } else if (keyName === 'tax_id') {
        if (!value) {
          errorsFields.tax_id = 'ⓘ Business tax-ID is required.';
          error = true;
        } else if (!FormValidators.isValidTaxID(value as string)) {
          errorsFields.tax_id =
            'ⓘ Business tax-ID must be 9 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'tax_type') {
        if (
          !value ||
          (value !== BusinessTaxType.licensed_il &&
            value !== BusinessTaxType.exempt)
        ) {
          errorsFields.tax_type = 'ⓘ Business tax type is required.';
          error = true;
        }
      } else if (keyName === 'bank_account_number') {
        if (!value) {
          errorsFields.bank_account_number =
            'ⓘ Business bank account number is required.';
          error = true;
        } else if (
          !FormValidators.isValidBankAccountNumber(value?.toString() ?? '')
        ) {
          errorsFields.bank_account_number =
            'ⓘ Business bank account number must be 5-7 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'bank_branch_number') {
        if (!value) {
          errorsFields.bank_branch_number =
            'ⓘ Business bank branch number is required.';
          error = true;
        } else if (
          !FormValidators.isValidBankBranchNumber(value?.toString() ?? '')
        ) {
          errorsFields.bank_branch_number =
            'ⓘ Business bank branch number must be 3 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'bank_number') {
        if (!value) {
          errorsFields.bank_number = 'ⓘ Business bank number is required.';
          error = true;
        } else if (!FormValidators.isValidBankNumber(value?.toString() ?? '')) {
          errorsFields.bank_number =
            'ⓘ Business bank number must be 2 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'business_website_url') {
        if ((value as string)?.trim().length > 0) {
          if (!FormValidators.isURL(value as string)) {
            errorsFields.business_website_url =
              'ⓘ Business social-media/website link should be valid URL.';
            error = true;
          }
        }
      } else if (keyName === 'business_social_media_url') {
        if ((value as string)?.trim().length > 0) {
          if (!FormValidators.isURL(value as string)) {
            errorsFields.business_social_media_url =
              'ⓘ Business social-media/website link should be valid URL.';
            error = true;
          }
        }
      }
    });
    if (error) {
      toast.warn('Please check the form for errors.');
    }
    setErrors(errorsFields);
    return error;
  };

  const handelNext = () => {
    console.log(formData);
    if (!hasValidationErrors()) {
      setCurrentStep(step + 1);
    }
    return;
  };
  const handleTaxTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleUserInput({
      target: {
        name: 'tax_type',
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <div className="justify-content-center p-5">
        <div className="row justify-content-center">
          {/* Business Name */}
          <div className="col-12 col-md-6 g-3">
            <Input
              name="name_en"
              label="Business name"
              placeholder="Enter business name"
              isRequired={true}
              value={formData.name_en}
              onChange={handleUserInput}
              aria-label="Business name"
              isInvalid={!!errors.name_en}
              errorMessage={errors.name_en}
              color={errors.name_en ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
          <div className="col-12 col-md-6 g-3 ">
            <Input
              name="name_ar"
              label="Business name (عربي)"
              placeholder="Enter business name (عربي)"
              isRequired={true}
              value={formData.name_ar}
              onChange={handleUserInput}
              aria-label="Business name (عربي)"
              isInvalid={!!errors.name_ar}
              errorMessage={errors.name_ar}
              color={errors.name_ar ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
              style={{ direction: 'rtl' }} // Right-to-left text direction
            />
          </div>
          <hr className="m-3"></hr>
          {/* Business Owner Name */}
          <div className="col-12 col-md-12 g-3">
            <Input
              name="business_owner_full_name"
              label="Business owner/manager name"
              placeholder="Enter full name"
              isRequired={true}
              value={formData.business_owner_full_name}
              onChange={handleUserInput}
              aria-label="Business owner/manager name"
              isInvalid={!!errors.business_owner_full_name}
              errorMessage={errors.business_owner_full_name}
              color={errors.business_owner_full_name ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
          <hr className="m-3"></hr>

          {/* Phone Number */}
          <div className="row text-center">
            <div className="col-12 col-md-3 g-3">
              <CountrySelectForm
                setSelectedCountry={setSelectedCountry}
                value={CurrentContries[0]}
              />
            </div>
            <div className="col-12 col-md-9 g-3">
              <Input
                name="business_phone"
                label="Phone number"
                value={formData.business_phone}
                isRequired={true}
                onChange={handleUserInput}
                aria-label="Phone number"
                isInvalid={!!errors.business_phone}
                errorMessage={errors.business_phone}
                color={errors.business_phone ? 'danger' : 'success'}
                variant="bordered"
                maxLength={13}
                placeholder="05XXXXXXXX"
                fullWidth
                style={{
                  border: 'none',
                  marginTop: '0',
                  boxShadow: '0 0 0 .25rem rgb(5, 174, 75, .25) !important',
                }}
              />
            </div>
          </div>
          <hr className="m-3"></hr>

          {/* Email and Website */}
          <div className="col-12 col-md-12 g-3">
            <Input
              name="business_email"
              label="Business email"
              placeholder="Enter business email"
              isRequired={true}
              value={formData.business_email}
              onChange={handleUserInput}
              aria-label="Business email"
              isInvalid={!!errors.business_email}
              errorMessage={errors.business_email}
              color={errors.business_email ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
          <hr className="m-3"></hr>

          <div className="col-12 col-md-6 g-3">
            <Input
              onChange={handleUserInput}
              label="Business website or profile on social media"
              name="business_website_url"
              type="text"
              value={
                OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                  ? SIGNUP_FORM_DEBUG_DATA.business_social_media_url
                  : formData.business_website_url
              }
              placeholder="https://www.business_website.com"
              aria-label="Business website"
              isInvalid={!!errors.business_website_url}
              errorMessage={errors.business_website_url}
              color={errors.business_website_url ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>

          {/* Additional Social Media URL */}
          <div className="col-12 col-md-6 g-3">
            <Input
              onChange={handleUserInput}
              name="business_social_media_url"
              label="Another business website or profile on social media"
              type="text"
              value={
                OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                  ? SIGNUP_FORM_DEBUG_DATA.business_social_media_url
                  : formData.business_social_media_url
              }
              placeholder="https://www.instagram.com/business_profile"
              aria-label="Business social media"
              isInvalid={!!errors.business_social_media_url}
              errorMessage={errors.business_social_media_url}
              color={errors.business_social_media_url ? 'danger' : 'success'}
              variant="bordered"
            />
          </div>
          <hr className="m-3"></hr>

          {/* Tax ID and Tax Type */}
          <div className="col-12 col-md-12 g-3">
            <Input
              name="tax_id"
              label="Business Tax ID"
              isRequired={true}
              type="text"
              value={
                OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                  ? SIGNUP_FORM_DEBUG_DATA.tax_id
                  : formData.tax_id
              }
              placeholder="000-000-000"
              isInvalid={!!errors.tax_id}
              errorMessage={errors.tax_id}
              color={errors.tax_id ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
              required
              onChange={(e) => handleUserInput(e)}
            />
          </div>

          <div className="col-12 col-md-12 g-3 mt-4">
            <div className="category col-12 d-flex justify-content">
              <RadioGroup
                label="Select Your Business Tax Type"
                className="text-medium"
                value={formData.tax_type}
                isRequired={true}
                onChange={handleTaxTypeChange}
                orientation="horizontal"
                description={
                  errors.tax_type ? (
                    <span style={{ color: 'red' }}>{errors.tax_type}</span>
                  ) : null
                }
              >
                <Radio value={BusinessTaxType.licensed_il}>
                  <div className="flex flex-col">
                    <span>Licensed/LTD · עסק מורשה · مرخّص</span>
                    <p className="text-small text-default">
                      You'll be charged {CURRENT_VAT * 100}% VAT on your sales +
                      the deduction-at-source (if applicable).
                    </p>
                  </div>
                </Radio>
                <Spacer y={10} />
                <Radio value={BusinessTaxType.exempt}>
                  <div className="flex flex-col">
                    <span>Exempt · עסק פטור · معفي</span>
                    <p className="text-small text-default">
                      You'll be charged 0% VAT on your sales + the
                      deduction-at-source (if applicable).
                    </p>
                  </div>
                </Radio>
              </RadioGroup>
            </div>
          </div>
          <hr className="m-3"></hr>

          {/* Bank Information */}
          <div className="col-12 col-md-4 g-3 ">
            <Input
              name="bank_account_number"
              onChange={handleUserInput}
              label="Bank account number"
              isRequired={true}
              type="text"
              value={
                OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                  ? SIGNUP_FORM_DEBUG_DATA.bank_account_number.toString()
                  : formData.bank_account_number?.toString()
              }
              placeholder="0000000"
              aria-label="Bank account number"
              isInvalid={!!errors.bank_account_number}
              errorMessage={errors.bank_account_number}
              color={errors.bank_account_number ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
          <div className="col-12 col-md-4 g-3 ">
            <Input
              name="bank_branch_number"
              onChange={handleUserInput}
              label="Bank branch number"
              isRequired={true}
              type="text"
              value={
                OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                  ? SIGNUP_FORM_DEBUG_DATA.bank_branch_number.toString()
                  : formData.bank_branch_number?.toString()
              }
              placeholder="000"
              aria-label="Bank branch number"
              isInvalid={!!errors.bank_branch_number}
              errorMessage={errors.bank_branch_number}
              color={errors.bank_branch_number ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
          <div className="col-12 col-md-4 g-3 ">
            <Input
              name="bank_number"
              onChange={handleUserInput}
              label="Bank number"
              isRequired={true}
              type="text"
              value={
                OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                  ? SIGNUP_FORM_DEBUG_DATA.bank_number.toString()
                  : formData.bank_number?.toString()
              }
              placeholder="00"
              aria-label="Bank number"
              isInvalid={!!errors.bank_number}
              errorMessage={errors.bank_number}
              color={errors.bank_branch_number ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
        </div>
      </div>

      <FormFooter
        onClickNext={() => handelNext()}
        onClickBack={() => setModalState(true)}
        currentStep={step}
      />
    </>
  );
}
export default Form1;
