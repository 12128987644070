import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Backbutton from 'pages/auth/components/back_button';
import Button from 'silal_app_base_react/src/components/buttons/index';
import Wrapper from './reset_password_screen.styled';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { toast } from 'react-toastify';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Input } from '@nextui-org/react';

function Resetpass() {
  const [formValue, setFormValue] = useState('');
  const navigate = useNavigate();

  const sendOTPToEmail = async () => {
    await AuthenticationRepository.loginSendEmailOTP(formValue).then((res) => {
      if (!res) return;
      toast.success(res.msg);
      navigate('/email-otp-verification', {
        state: {
          previousPage: 'reset_password',
          email: formValue,
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  return (
    <BackgroundContainer>
      <Wrapper>
        <div className="top-row">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              baselineShift: 'center',
            }}
          >
            <div className="go-back-button">
              <Backbutton primaryIcon={true} />
            </div>
            <div className="title">Forgot password?</div>
            <div />
          </div>
          <div>
            <h3>Enter your email</h3>
            <p>
              If the email is registered, we will send a verification code to it
              and then you can access your account.
            </p>
          </div>
          <div className="form">
            <Input
              isRequired
              type="email"
              id="email"
              label="Email"
              variant="bordered"
              placeholder="email.example@gmail.com"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormValue(e.target.value)
              }
            />
          </div>
          <div />
        </div>
        <div className="bottom-row">
          <div className="form-submit">
            <Button
              onClick={sendOTPToEmail}
              style={{ width: '100%', height: '45px', fontSize: '16px' }}
            >
              Send Code
            </Button>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default Resetpass;
