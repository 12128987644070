import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  max-width: 430px;
  height: fit-content;
  border-radius: 30px;
  padding: 0px 30px 30px 30px;
  font-family: ${Fonts.secondary}, sans-serif;
  margin: 20px;
  position: relative;

  .top-row {
    .title {
      padding: 25px 0;
      h1 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
      }
    }
    .buttonGroups {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 2px;
      background: #f5f5f5;
      border-radius: 5px;
      button {
        width: 50%;
        transition: 0.3s ease;
        :hover {
          box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.11);
        }
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form-control-mobile {
        margin-top: 20px;
        label {
          font-size: 15px;
          font-weight: 700;
          color: #4c6870;
        }
        .row {
          border: 1px solid #ededed;
          border-radius: 10px;
          margin-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .col1 {
            max-width: 130px;
            width: 100%;
            .value-container {
              border-right: none;
              border-radius: 5px 0px 0px 5px;
            }
          }
          .col2 {
            input {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }
      }
      /* email form design  */
      .form-control-email {
        .row {
          margin-top: 20px;
          label {
            font-size: 15px;
            font-weight: 700;
            color: #4c6870;
          }
        }
      }
    }
  }
  .otp-btn {
    color: #05ae4b;
  }
  .bottom-row {
    text-align: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
    margin-top: 50px;
    position: static;
    .forgot-pass-text {
      font-size: 15px;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 20px;
      a {
        text-decoration: none;
        margin-left: 10px;
        font-weight: bold;
        color: #05ae4b;
      }
    }
  }
`;

export default Wrapper;
