import { silal_items_backhground_placeholder } from 'assets/index';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Spinner from 'silal_app_base_react/src/components/spinner';

export const BackgroundContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = silal_items_backhground_placeholder;
    image.onload = () => {
      setTimeout(() => {
        setImageLoaded(true);
      }, 500);
    };
  }, []);

  return !isImageLoaded ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Spinner />
    </div>
  ) : (
    <div
      style={{
        background: `url(${silal_items_backhground_placeholder}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

export const BackgroundContainerStyled = styled.div`
  background: url(${silal_items_backhground_placeholder}) no-repeat center
    center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
