import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Table } from 'antd';
import Nav from '../components/navbar';
import Spinner from 'silal_app_base_react/src/components/spinner';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import { ExpandedComponent } from 'pages/products/products_page';
import {
  CURRENT_CURRENCY,
  LISTING_STATUS,
  LISTING_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  productPlaceholder_path,
  notFoundIllustration_path,
} from 'assets/index';
import { getMinMaxPrice } from 'pages/products/functions/products_functions';
import {
  Listing,
  ListingItem,
} from 'silal_app_base_react/src/data/types/listings';
import { getCurrentStore } from 'core/hooks/use_selector';
import ListingsRepository from 'data/repositories/listings_repository';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { ListingActionButtons } from 'silal_app_base_react/src/pages/listing_profile/components/action_buttons';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import Lightbox from 'react-image-lightbox';

const OutOfStockScreen = () => {
  const [items, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(true);
  const currentStore = useSelector(getCurrentStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [image, setImage] = useState<ImageType | null>(null);
  const handleClose = () => {
    setImage(null);
  };
  useEffect(() => {
    async function fetchSubCategories() {
      const res = await ListingsRepository.paginateListingsDetails({
        store_id: currentStore.id,
        page: 1,
        per_page: 500,
      });

      if (res) {
        const filteredListings = res.filter(
          (listing: Listing) =>
            listing.listing_type_value === LISTING_TYPES.public_listing &&
            listing.items.some((item) => !item.in_stock),
        );
        setListings(filteredListings);
      }
      setLoading(false);
    }

    fetchSubCategories();
  }, [currentStore.id]);

  const handleEdit = (row: Listing) => {
    navigate(`/products/listing-details/${row.id}`, {
      state: row,
    });
  };

  const handleChangeInStockAndPrice = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          {
            item_id: item.id,
            price: item.price,
            in_stock: !item.in_stock,
          },
        ],
      },
      success: (response: any) => {
        if (!response) return;

        if (response.updated_items) {
          const updatedItem = response.updated_items[0];

          const updatedListings = items.map((listing) => {
            if (listing.id === data.id) {
              const updatedItems = listing.items.map((listItem) =>
                listItem.id === updatedItem.id ? updatedItem : listItem,
              );
              return { ...listing, items: updatedItems };
            }
            return listing;
          });

          const filteredListings = updatedListings.filter((listing) =>
            listing.items.some((listItem) => !listItem.in_stock),
          );

          setListings(filteredListings);
        }
      },
      failure: (error: any) => {
        console.error('Update failed:', error);
      },
    };

    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'images',
      key: 'photo',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => {
        const img_i = row.images.findIndex(
          (img) => img.id === row?.cover_image_id,
        );
        return row.images.length > 0 ? (
          <ShowImage
            image={row.images[img_i]}
            style={{ width: '100px', height: '100px', objectFit: 'scale-down' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setImage(row.images[img_i]);
            }}
          />
        ) : (
          <img src={productPlaceholder_path} alt="Placeholder" />
        );
      },
    },
    {
      title: 'Item Name',
      dataIndex: 'name_en',
      key: 'name',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <>
          #{row.id} {row.name_en} <br />
          {row.name_ar}
        </>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.name_en.localeCompare(b.name_en) ||
        a.name_ar.localeCompare(b.name_ar),
    },
    {
      title: 'Variations',
      dataIndex: 'items',
      key: 'variations',
      align: 'center' as const,
      render: (items: ListingItem[]) => items.length,
    },
    {
      title: 'Last Purchase',
      dataIndex: 'last_purchase_date',
      key: 'lastPurchase',
      align: 'center' as const,
      render: (date: string) => formateServerDateToLocaleDate(date),
    },
    {
      title: 'Current Orders',
      dataIndex: 'current_orders',
      key: 'currentOrders',
      align: 'center' as const,
      render: (orders: number) => orders?.toString() || '0',
    },
    {
      title: 'Price',
      dataIndex: 'items',
      key: 'price',
      align: 'center' as const,
      render: (items: ListingItem[]) =>
        items.length > 0
          ? `${CURRENT_CURRENCY}${getMinMaxPrice(items)}`
          : `${CURRENT_CURRENCY}0`,
      sorter: (a: Listing, b: Listing) =>
        parseFloat(getMinMaxPrice(a.items).toString()) -
        parseFloat(getMinMaxPrice(b.items).toString()),
    },
    {
      title: 'Actions',
      key: 'edit',
      align: 'center' as const,
      render: (_: unknown, row: Listing) => (
        <ListingActionButtons
          showDeleteButton={true}
          showHideButton={true}
          showDetailsButton={true}
          listing={row}
          ListingsRepository={ListingsRepository}
          store_id={currentStore.id}
          onHideListingSuccess={(listingId: number) => {
            const newItems: Listing[] | undefined = items?.map((x) => {
              if (x.id === listingId) {
                return {
                  ...x,
                  freezed_by_seller: !x.freezed_by_seller,
                  listing_status_num: LISTING_STATUS.changed,
                };
              }
              return x;
            });
            setListings(newItems?.filter((i) => i));
          }}
        />
      ),
    },
  ];

  return (
    <div>
      {image && (
        <Lightbox
          mainSrc={(image as ImageType).public_s3_url}
          imageTitle={(image as ImageType).alt || 'No Alt Text'}
          mainSrcThumbnail={(image as ImageType).public_s3_url}
          onCloseRequest={handleClose}
        />
      )}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30%',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <Nav />
          <Row className="pt-3" style={{ overflowX: 'auto' }}>
            {items.length === 0 ? (
              <div className="no-orders">
                <img src={notFoundIllustration_path} alt="no-orders" />
                <h3>No products were found</h3>
              </div>
            ) : (
              <Table
                size="middle"
                columns={columns}
                dataSource={items}
                rowKey="id"
                pagination={{ pageSize: 30 }}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <ExpandedComponent
                      data={record}
                      handleChangeInStockAndPrice={handleChangeInStockAndPrice}
                    />
                  ),
                }}
              />
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default OutOfStockScreen;
