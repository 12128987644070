import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Backbutton from 'pages/auth/components/back_button';
import * as userAction from 'store/user/user_actions';
import { fullLogout } from 'routes/functions/routes_functions';
import SellerRepository from 'data/repositories/seller_repository';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Input, Button } from '@nextui-org/react';

import { Fonts } from 'silal_app_base_react/src/config/theme';
import { toast } from 'react-toastify';

function SellerInfo() {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    idNumber: '',
  });
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const addPersonalInfo = async () => {
    const { firstName, lastName, idNumber } = userData;

    if (!FormValidators.isValidName(firstName)) {
      return setError(
        'First name filed is required, should be in English or Arabic, and at least 2 characters long',
      );
    }

    if (!FormValidators.isValidName(lastName)) {
      return setError(
        'Last name filed is required, should be in English or Arabic, and at least 2 characters long',
      );
    }

    if (!FormValidators.isValidID(idNumber)) {
      return setError('ID number field is required, and must be 9 digits long');
    }

    toast.info('Submitting your information, please wait...');

    await SellerRepository.addPersonalInfo(
      firstName.trim(),
      lastName.trim(),
      parseInt(idNumber),
    ).then((res) => {
      if (!res) return;
      dispatch(userAction.actionGetSetUserProfile(res));
    });
  };

  return (
    <BackgroundContainer>
      <div className="container">
        <Wrapper>
          <div className="go-back-button">
            <Backbutton
              primaryIcon={true}
              customBackFunction={() => fullLogout(dispatch, navigate)}
            />
            <h2>Merchant Information</h2>
            <div />
          </div>
          <div className="content row mt-3">
            <div className="col-12 col-lg-12 mb-3">
              <Input
                isRequired
                label="First Name"
                type="text"
                name="firstName"
                value={userData.firstName}
                variant="bordered"
                placeholder="Enter your first name"
                onChange={handleUserInput}
                fullWidth
              />
            </div>
            <div className="col-12 col-lg-12 mb-3">
              <Input
                isRequired
                label="Last Name"
                type="text"
                name="lastName"
                value={userData.lastName}
                variant="bordered"
                placeholder="Enter your last name"
                onChange={handleUserInput}
                fullWidth
              />
            </div>{' '}
            <div className="col-12 col-lg-12 mb-3">
              <Input
                isRequired
                label="ID Number"
                type="text"
                name="idNumber"
                value={userData.idNumber}
                variant="bordered"
                placeholder="Enter your ID number"
                onChange={handleUserInput}
                fullWidth
              />
            </div>
            <div>
              {error !== '' ? (
                <span className="text-danger">{error}</span>
              ) : null}
            </div>
          </div>

          <Button
            onPress={addPersonalInfo}
            color="success"
            className="w-full p-0"
          >
            Submit
          </Button>
        </Wrapper>
      </div>
    </BackgroundContainer>
  );
}

export default SellerInfo;
const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: auto;
  .content {
    padding: 10px 10px;
  }
  .go-back-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h2 {
    font-weight: 700;
    font-size: 22px;
    color: #002733;
    margin: 0;
  }
  .butns {
    text-align: right;
    padding-right: 26px;
  }
  .btn1 {
    background: #ccd4d6;
    color: #4c7061;
    border: none;
    margin: 5px;
    padding: 11px 73px;
  }
  .btn2 {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 100%;
  }
`;
