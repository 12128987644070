import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.greenMain};
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    z-index: 1;

    .whitebox {
      width: 350px;
      height: 350px;
      border: none;
      z-index: -1;
    }

    h2 {
      font-size: 68px;
      font-weight: 700;
      font-family: 'Poppins', sans-serif;
      line-height: 50px;
      color: #ffffff;
      margin-top: 20px;
      z-index: 1;
    }

    p {
      font-size: 38px;
      font-weight: 400;
      font-family: ${Fonts.secondary}, sans-serif;
      line-height: 40px;
      z-index: 1;
    }
  }
`;

export default Wrapper;
