import ListingsRepository from 'data/repositories/listings_repository';
import {
  Listing,
  ListingItem,
} from 'silal_app_base_react/src/data/types/listings';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import { AttributeAssociation } from 'silal_app_base_react/src/data/types/listings';
import { formatDecimals } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { productPlaceholder_path } from 'assets/index';
import { useState } from 'react';
import type { TableColumnsType } from 'antd';

export const getMinMaxPrice = (items: ListingItem[]) => {
  const minParsed = Math.min(
    ...items.map((item) => {
      if (typeof item.price === 'number') {
        return item.price;
      }
      return parseInt(item.price);
    }),
  );
  const maxParsed = Math.max(
    ...items.map((item) => {
      if (typeof item.price === 'number') {
        return item.price;
      }
      return parseInt(item.price);
    }),
  );
  return minParsed === maxParsed ? maxParsed : minParsed + '-' + maxParsed;
};

export async function fetchCategoryListings(
  setListings: (arg0: any) => void,
  store_id: number,
  categoryId: string | undefined,
  setLoading: (arg0: boolean) => void,
) {
  setLoading(true);
  if (categoryId) {
    await ListingsRepository.paginateListingsOfCategoryID({
      store_id: store_id,
      category_id: parseInt(categoryId!),
      page: 1,
      per_page: 10000,
    }).then((res) => {
      if (!res) return;
      setListings(res!);
    });
  } else {
    await ListingsRepository.paginateListingsDetails({
      store_id: store_id,
      page: 1,
      per_page: 10000,
    }).then((res) => {
      if (!res) return;
      setListings(res!);
    });
  }
  setLoading(false);
}

export const getAttributeValue = (attribute: AttributeAssociation) => {
  return typeof attribute.attr_value_en === 'boolean'
    ? JSON.stringify(attribute.attr_value_en)
    : attribute.attribute_type === AttributeType.NUMERIC
      ? formatDecimals(attribute.attr_value_en)
      : attribute.attr_value_en + ' - ' + attribute.attr_value_ar;
};

export const getExpandableTableHeaders = (data: Listing) => {
  const columns: TableColumnsType = [
    {
      title: 'Item Id',
      dataIndex: 'itemId',
      key: 'itemId',
      align: 'center' as const,
    },
    ...(data?.items[0]?.attributes.map(
      (attr: AttributeAssociation, index: number) => ({
        title: `${attr.attr_name_en} - ${attr.attr_name_ar} ${
          attr.unit ? `(${attr.unit})` : ''
        }`,
        dataIndex: `attribute_${index}`,
        key: `attribute_${index}`,
        align: 'center' as const,
      }),
    ) || []),
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as const,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      align: 'center' as const,
    },
    {
      title: 'In Stock',
      dataIndex: 'in_stock',
      key: 'in_stock',
      align: 'center' as const,
    },
  ];

  return columns;
};

export const getExpandableTableBody = (
  data: Listing,
  handleChangeInStock: any,
  onImageClick?: (i: number) => void,
) => {
  const [prices, setPrices] = useState(
    data?.items?.map((item: ListingItem) => item.price),
  );

  // Map `data.items` to a dataSource for Ant Design's Table
  const dataSource = data?.items.map((item: ListingItem, index: number) => ({
    key: item.id, // Unique key for each row
    itemId: `#${item.id}`, // Format the item ID
    ...item.attributes.reduce(
      (acc, attr, attrIndex) => ({
        ...acc,
        [`attribute_${attrIndex}`]: attr.attr_value_en, // Use appropriate value
      }),
      {},
    ),
    price: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <input
          type="number"
          className="form-control"
          style={{
            width: '100px',
            height: '30px',
            textAlign: 'center',
          }}
          defaultValue={item.price}
          onBlur={(e) => {
            const newPrices = [...prices];
            newPrices[index] = e.target.value;
            setPrices(newPrices);
            handleChangeInStock(
              {
                ...item,
                in_stock: !item.in_stock,
                price: e.target.value,
              },
              data,
            );
          }}
        />
      </div>
    ),
    image: item.image?.id ? (
      <div
        style={{
          width: '60px',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <ShowImage
          image={item.image}
          onClick={() => {
            if (onImageClick) {
              onImageClick(index);
            }
          }}
          style={{
            width: '50px',
            height: '50px',
            objectFit: 'scale-down',
          }}
        />
      </div>
    ) : (
      <img src={productPlaceholder_path} alt="Product Placeholder" />
    ),
    in_stock: (
      <input
        type="checkbox"
        checked={item.in_stock}
        onChange={() => handleChangeInStock(item, data)}
      />
    ),
  }));

  return dataSource;
};
