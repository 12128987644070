import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import FormFooter from './form_footer';
import { StoreSignUpForm } from 'data/types/store';
import { DropDownOption } from 'data/types/general';
import { Town } from 'silal_app_base_react/src/data/types/address';
import { CurrentRegions } from 'core/constants/constants';
import { toast } from 'react-toastify';
import { DefaultLocationCoords } from 'silal_app_base_react/src/config/constants';
import { Button, Input, Textarea } from '@nextui-org/react';
import { Select, SelectItem } from '@nextui-org/react';
import MapModal from 'components/maps/map_modal';
import { FaMapLocation } from 'react-icons/fa6';

type Form2Props = {
  setCurrentStep: (value: number) => void;
  step: number;
  formData: StoreSignUpForm;
  setFormData: (value: StoreSignUpForm) => void;
  handleUserInput: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
};

function Form2({
  setCurrentStep,
  step,
  formData,
  setFormData,
  handleUserInput,
}: Form2Props) {
  const [townsObjs, setTownsObjs] = useState<Town[]>();
  const [towns, setTowns] = useState<DropDownOption[]>();
  const [cities, setCities] = useState<DropDownOption[]>([]);

  const [showMapModal, setMapModal] = useState(false);
  const [coords, setCoords] = useState(DefaultLocationCoords);

  const [errors, setErrors] = useState({
    address_line_1: '',
    address2: '',
    town_id: '',
    notes: '',
    lat: '',
    lng: '',
    region_id: '',
    city_id: '',
  });

  const [isReady, setIsReady] = useState({
    region: false,
    city: false,
    town: false,
  });

  useEffect(() => {
    fetchCities();
    /* eslint-disable */
  }, [formData.address.region_id, formData.address.city_id]);

  useEffect(() => {
    fetchTowns();
  }, [formData.address.city_id]);

  useEffect(() => {
    if (formData.address.city_id != null && formData.address.city_id !== '') {
      fetchTowns();
    } else {
      fetchCities();
      fetchTowns();
    }
  }, []);

  const fetchCities = async () => {
    const region_id = formData.address.region_id;
    if (region_id) {
      const cities = (await SharedRepository.getCities(region_id)) ?? [];
      if (!cities) {
        return;
      }
      setCities(
        cities.map((city) => ({
          label: `${city.name_en} | ${city.name_ar}`,
          value: city.id,
        })),
      );
      setIsReady((prev) => ({ ...prev, city: true })); // Enable the city dropdown
    } else {
      setCities([]);
      setIsReady((prev) => ({ ...prev, city: false })); // Disable the city dropdown
    }
  };

  const fetchTowns = async () => {
    const city_id = formData.address.city_id;
    if (city_id) {
      const towns = (await SharedRepository.getTowns(city_id)) ?? [];
      if (!towns) {
        return;
      }
      setTownsObjs(towns);
      setTowns(
        towns.map((town) => ({
          label: `${town.name_en} | ${town.name_ar}`,
          value: town.id,
        })),
      );
      setIsReady((prev) => ({ ...prev, town: true })); // Enable the town dropdown
    } else {
      setTowns([]);
      setIsReady((prev) => ({ ...prev, town: false })); // Disable the town dropdown
    }
  };

  useEffect(() => {
    if (formData.address.town_id) {
      towns?.forEach((town) => {
        if (town.value.toString() === formData.address.town_id.toString()) {
          setInitialCoords({ value: town.value });
        }
      });
    }
  }, [towns]);

  const handleValidations = () => {
    const errorsFields = {
      address_line_1: '',
      address2: '',
      town_id: '',
      notes: '',
      lat: '',
      lng: '',
      region_id: '',
      city_id: '',
    };
    let error = false;
    const address = { ...formData.address };
    Object.keys(address).map(function (keyName) {
      const value = address[keyName as keyof typeof address];
      if (keyName === 'address_line_1') {
        if (!value || value === '') {
          errorsFields.address_line_1 = 'ⓘ Address details is required.';
          error = true;
        }

        if ((value as string).length > 64) {
          errorsFields.address_line_1 =
            'ⓘ Address details length must be less then 64 characters.';
          error = true;
        }
      } else if (keyName === 'town_id') {
        if (!value) {
          errorsFields.town_id = 'ⓘ Town is required.';
          error = true;
        }
      } else if (keyName === 'notes') {
        if ((value as string).length > 0) {
          if ((value as string).length > 128) {
            errorsFields.notes =
              'ⓘ Notes length must be less then 128 characters.';
            error = true;
          }
        }
      } else if (keyName === 'lat') {
        if (!value) {
          errorsFields.lat = 'ⓘ Latitude are required.';
          error = true;
        }
      } else if (keyName === 'lng') {
        if (!value) {
          errorsFields.lng = 'ⓘ Longitude are required.';
          error = true;
        }
      } else if (keyName === 'region_id') {
        if (!value) {
          errorsFields.region_id = 'ⓘ Region is Required.';
          error = true;
        }
      } else if (keyName === 'city_id') {
        if (!value) {
          errorsFields.city_id = 'ⓘ City is Required.';
          error = true;
        }
      }
    });
    if (error) {
      toast.warn('Please check the form for errors.');
    }
    setErrors(errorsFields);

    return error;
  };

  const handelNext = () => {
    if (!handleValidations()) {
      return setCurrentStep(step + 1);
    }
  };

  const setInitialCoords = (option: any) => {
    let value = option?.value?.toString();
    const location = townsObjs?.find((town) => town.id.toString() === value);
    if (location && location.lat && location.lng) {
      setCoords({ lat: location.lat, lng: location.lng });
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          lat: location.lat.toString(),
          lng: location.lng.toString(),
        },
      });
    } else {
      setCoords(DefaultLocationCoords);
    }
  };

  const handleSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    name: string,
  ) => {
    const value = e.target.value;

    if (name === 'region_id') {
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          region_id: value.toString() || '',
          city_id: '',
          town_id: '',
        },
      });
    } else if (name === 'city_id') {
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          city_id: value.toString(),
          town_id: '',
        },
      });
    } else if (name === 'town_id') {
      setFormData({
        ...formData,
        address: { ...formData.address, town_id: value.toString() },
      });
      setInitialCoords(formData.address.town_id); // Use value to set coordinates for the town
    }
  };

  return (
    <>
      <div className="justify-content-center p-5">
        <MapModal
          show={showMapModal}
          onHide={() => setMapModal(false)}
          setFormData={setFormData}
          formData={formData}
          initial_coords={
            formData?.address?.lat && formData?.address?.lng
              ? formData.address
              : coords
          }
        />
        <div className="row justify-content-center">
          <h6>
            Store Location<span>*</span>
          </h6>
          <div className="col-12 col-md-12 g-3">
            <Input
              label="Store Address (عربي)"
              name="address_line_1"
              onChange={handleUserInput}
              isRequired={true}
              type="text"
              value={formData.address.address_line_1}
              placeholder="عنوان المتجر"
              aria-label="Store address"
              isInvalid={!!errors.address_line_1}
              errorMessage={errors.address_line_1}
              color={errors.address_line_1 ? 'danger' : 'success'}
              variant="bordered"
              fullWidth
            />
          </div>
          <div className="col-12 col-md-12 g-3">
            <Input
              type="text"
              label="More details about the store address (عربي)"
              name="address_line_2"
              value={formData.address.address_line_2}
              onChange={handleUserInput}
              placeholder="تفاصيل العنوان"
              aria-label="Additional store address"
              variant="bordered"
              fullWidth
            />
          </div>

          <div className="business">
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 g-3">
                <Select
                  isRequired
                  aria-label="Select Region"
                  label="Region"
                  placeholder="Select a region"
                  selectedKeys={
                    new Set(
                      formData.address.region_id
                        ? [formData.address.region_id.toString()]
                        : [],
                    )
                  }
                  onChange={(value) => handleSelect(value, 'region_id')}
                  isInvalid={!!errors.region_id}
                  errorMessage={errors.region_id}
                  color={errors.region_id ? 'danger' : 'success'}
                  variant="bordered"
                >
                  {CurrentRegions.map((region) => (
                    <SelectItem
                      key={String(region.value)}
                      value={String(region.value)}
                    >
                      {region.label}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="col-12 col-md-4 g-3">
                {isReady.city && (
                  <Select
                    isRequired
                    aria-label="Select City"
                    label="City"
                    placeholder="Select a city"
                    selectedKeys={
                      new Set(
                        formData.address.city_id
                          ? [formData.address.city_id.toString()]
                          : [],
                      )
                    }
                    onChange={(value) => handleSelect(value, 'city_id')}
                    isInvalid={!!errors.city_id}
                    errorMessage={errors.city_id}
                    color={errors.city_id ? 'danger' : 'success'}
                    variant="bordered"
                  >
                    {cities.length > 0 ? (
                      cities.map((city) => (
                        <SelectItem
                          key={String(city.value)}
                          value={String(city.value)}
                        >
                          {city.label}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem key="no-cities" isDisabled value="">
                        No cities available
                      </SelectItem>
                    )}
                  </Select>
                )}
              </div>
              <div className="col-12 col-md-4 g-3">
                {isReady.town && (
                  <Select
                    isRequired
                    aria-label="Select Town"
                    label="Town"
                    placeholder="Select a town"
                    selectedKeys={
                      new Set(
                        formData.address.town_id
                          ? [formData.address.town_id.toString()]
                          : [],
                      )
                    }
                    onChange={(value) => {
                      handleSelect(value, 'town_id');
                    }}
                    isInvalid={!!errors.town_id}
                    errorMessage={errors.town_id}
                    color={errors.town_id ? 'danger' : 'success'}
                    variant="bordered"
                  >
                    {(towns || []).length > 0 ? (
                      (towns || []).map((town) => (
                        <SelectItem
                          key={String(town.value)}
                          value={String(town.value)}
                        >
                          {town.label}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem key="no-towns" isDisabled value="">
                        No towns available
                      </SelectItem>
                    )}
                  </Select>
                )}
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-6 g-3">
                <Input
                  type="number"
                  label="Longitude (إحداثيات الطول)"
                  name="lng"
                  value={formData.address.lng}
                  disabled
                  placeholder="Enter longitude"
                  isInvalid={!!errors.lng}
                  errorMessage={errors.lng} // Display the error message
                  color={errors.lng ? 'danger' : 'success'}
                  variant="bordered"
                  fullWidth
                  aria-label="Longitude"
                />
              </div>
              <div className="col-12 col-md-6 g-3">
                <Input
                  type="number"
                  label="Latitude (إحداثيات العرض)"
                  name="lat"
                  value={formData.address.lat}
                  disabled
                  placeholder="Enter latitude"
                  isInvalid={!!errors.lat}
                  errorMessage={errors.lat} // Display the error message
                  color={errors.lat ? 'danger' : 'success'}
                  variant="bordered"
                  fullWidth
                  aria-label="Latitude"
                />
              </div>

              <div className="col-12 col-md-12 g-3">
                <Button
                  onClick={() => setMapModal(true)}
                  style={{
                    width: '100%',
                  }}
                  color="success"
                  variant="flat"
                  isDisabled={coords.lat ? false : true}
                  endContent={<FaMapLocation />}
                >
                  Show Map
                </Button>
              </div>
            </div>
          </div>
          <hr className="m-3"></hr>

          <div className="col-12 col-md-12 g-3">
            <Textarea
              name="notes"
              label="Notes for delivery drivers"
              onChange={handleUserInput}
              placeholder="بجانب البنك العربي ..."
              value={formData.address.notes}
              aria-label="Notes"
              rows={10}
              variant="bordered"
              fullWidth
            />
          </div>
        </div>
      </div>
      <FormFooter
        currentStep={step}
        onClickNext={handelNext}
        onClickBack={() => setCurrentStep(step - 1)}
      />
    </>
  );
}

export default Form2;

const Form2Styled = styled.div``;
