import { useState, useEffect } from 'react';
import { Checkbox, TimeInputValue } from '@nextui-org/react';
import { TimeInput } from '@nextui-org/react';
import { Time } from '@internationalized/date';
import { WeekDay } from 'data/types/general';
import { StoreSignUpForm } from 'data/types/store';

type QuestionaireWorkingHoursProps = {
  day: WeekDay;
  formData: StoreSignUpForm;
  setFormData: (formData: StoreSignUpForm) => void;
  enabledWorkDaysCount: number;
  handleWorkingTimes: (key: string, value: string) => void;
  setEnabledWorkDaysCount: React.Dispatch<React.SetStateAction<number>>; // Correct typing for state updater
};

export default function WorkingHoursDaysRow({
  day,
  formData,
  setFormData,
  handleWorkingTimes,
  setEnabledWorkDaysCount,
}: QuestionaireWorkingHoursProps) {
  const [isEnabled, setIsEnabled] = useState<boolean>(true); // Set default to true to make checkbox selected initially

  // Load initial formData on component mount or whenever formData is updated
  useEffect(() => {
    // Initially, enable all checkboxes
    setIsEnabled(
      formData[day.start_key as keyof StoreSignUpForm] !==
        formData[day.end_key as keyof StoreSignUpForm],
    );
  }, []); // Empty dependency array ensures this runs once on component mount

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked; // Extract the boolean value
    setIsEnabled(checked); // Update the checkbox state immediately

    // Update enabledWorkDaysCount based on checkbox state
    setEnabledWorkDaysCount((prevCount) => {
      if (checked) {
        // If enabling, increment the count if it's less than 7
        return prevCount < 7 ? prevCount + 1 : prevCount;
      } else {
        // If disabling, decrement the count if it's greater than 0
        return prevCount > 0 ? prevCount - 1 : prevCount;
      }
    });

    // If checkbox is unchecked, set the formData to 08:00 for both opening and closing times
    if (!checked) {
      const updatedFormData = { ...formData };
      (updatedFormData[day.start_key as keyof StoreSignUpForm] as string) =
        '08:00'; // Type assertion
      (updatedFormData[day.end_key as keyof StoreSignUpForm] as string) =
        '08:00'; // Type assertion
      setFormData(updatedFormData);
    }
  };

  // Calculate opening and closing times dynamically based on isEnabled
  const openingTime = isEnabled
    ? new Time(
        parseInt(
          (formData[day.start_key as keyof StoreSignUpForm] as string).split(
            ':',
          )[0],
        ),
        parseInt(
          (formData[day.start_key as keyof StoreSignUpForm] as string).split(
            ':',
          )[1],
        ),
      )
    : new Time(8, 0); // Default to 08:00 if not enabled

  const closingTime = isEnabled
    ? new Time(
        parseInt(
          (formData[day.end_key as keyof StoreSignUpForm] as string).split(
            ':',
          )[0],
        ),
        parseInt(
          (formData[day.end_key as keyof StoreSignUpForm] as string).split(
            ':',
          )[1],
        ),
      )
    : new Time(8, 0); // Default to 22:00 if not enabled

  return (
    <div className="row mb-3" data-day={day.name_en}>
      <div className="col-12 col-md-2 g-3">
        <div className="form-check CheckBox mt-2">
          <Checkbox
            isSelected={isEnabled}
            onChange={handleCheckboxChange}
            size="md"
            className="mt-2 padd"
          >
            {day.name_en}
          </Checkbox>
        </div>
      </div>

      <div className="col-12 col-md-5 g-3 d-flex flex-column">
        <TimeInput
          label="Opening Time"
          hourCycle={24}
          value={openingTime as any}
          id={`timeinput-opening-${day.name_en}`} // Unique ID
          isDisabled={!isEnabled}
          variant="bordered"
          onChange={(newTime) => {
            if (!newTime) return;

            handleWorkingTimes(
              day.start_key,
              `${newTime.hour}:${newTime.minute < 10 ? '0' : ''}${newTime.minute}`,
            );
          }}
        />
      </div>

      <div className="col-12 col-md-5 g-3 d-flex flex-column">
        <TimeInput
          label="Closing Time"
          hourCycle={24}
          value={closingTime as any}
          isDisabled={!isEnabled}
          id={`timeinput-closing-${day.name_en}`} // Unique ID
          variant="bordered"
          onChange={(newTime) => {
            if (!newTime) return;
            handleWorkingTimes(
              day.end_key,
              `${newTime.hour}:${newTime.minute < 10 ? '0' : ''}${newTime.minute}`,
            );
          }}
        />
      </div>
    </div>
  );
}
