import styled from 'styled-components';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';

export const RejectModalWrapper = styled.div`
  .modal-header {
    padding: 30px 50px 10px 50px;
    justify-content: center;
    .btn-close {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }

  .footer {
    button {
      width: 100%;
    }
  }
  .para {
    font-family: ${Fonts.secondary};
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    color: #4c7061;
    line-height: 22px;
  }
  .modal-body {
    padding: 0 50px 40px 50px;
  }
`;

export const Wrapper = styled.div`
  .icon-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: none;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    opacity: 0.5;
  }

  .icon-btn:hover {
    opacity: 1;
  }
  .butns {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logout-btn {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
  }
  .stores {
    display: flex;
    padding-bottom: 30px;
  }
  h4 {
    padding-top: 20px;
  }
  h5 {
    padding: 30px 0px;
  }
  .text {
    padding: 50px 0px 0px 0px;
    font-weight: 400;
    font-size: 20px;
    color: #002733;
  }
  .separate {
    color: #05ae4b;
  }
  .btn2 {
    border: none;
    margin: 5px;
    font-size: 18px;
    width: 30%;
    height: 50px;
  }
  @media (max-width: 768px) {
    .btn2 {
      width: 80%;
    }
  }
  h4 {
    font-weight: 700;
    font-size: 28px;
    color: #002733;
  }
  h5 {
    font-weight: 700;
    font-size: 25px;
    color: #002733;
  }
  .selected-store {
    width: 232px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #cdefdb;
    border-radius: 10px;
    cursor: pointer;
    outline: 1px solid #05ae4b;
  }

  .unselected-store {
    width: 232px;
    height: 170px;
    background: rgba(4, 26, 33, 0.3);
    border-radius: 11px;
    background-color: #e5e5e5;
    p {
      font-family: ${Fonts.secondary};
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: center;
      color: #4c7061;
    }
  }
  .unselected-store {
    width: 232px;
    height: 170px;
    background: rgba(4, 26, 33, 0.3);
    border-radius: 11px;
    background-color: #e5e5e5;
    p {
      font-family: ${Fonts.secondary};
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: center;
      color: #4c7061;
    }
    .rejected-heading {
      font-family: ${Fonts.primary};
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;

      text-align: center;
      letter-spacing: -0.408px;

      color: #002733;
    }

    .rejected-btn {
      margin: 0 auto;
      width: 120px;
      border: none;
      font-family: ${Fonts.secondary};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      padding: 0.5rem 1rem;
      background: #f13005;
      border-radius: 5px;

      color: #ffffff;
    }
  }
  .underReview-heading {
    font-family: ${Fonts.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;

    text-align: center;
    letter-spacing: -0.408px;

    color: #002733;
  }

  .underReview-btn {
    background: #3799e0;
    border-radius: 5px;
    color: #ffffff;
    border: none;
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 0.5rem 1rem;

    color: #ffffff;
  }
  .img1 {
    padding: 10px;
    width: 190px !important;
    height: 165px !important;
  }
  h6 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    color: #002733;
  }
  .h6 {
    /* padding-top: 44px; */
  }
  .selected-store,
  .unselected-store {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .selected-store:hover {
    outline: 1px solid #05ae4b;
  }
  .unselected-store:hover {
    outline: 1px solid #05ae4b;
  }
  .box {
    box-sizing: border-box;
    padding-top: 25px;
    border: 1px solid rgba(205, 205, 208, 0.5);
    border-radius: 10px;
    cursor: pointer;
  }
  .img3 {
    border-radius: 50%;
  }
  .b6 {
    padding-top: 10px;
  }

  p {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #ccd4d6;
  }
  .del-button {
    /* background: #FAE1DA; */
    background: var(--color-del);
    border-radius: 3px;
    margin-left: 20px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-button {
    background: ${Colors.greenSecondary};
    color: white;
    border-radius: 3px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const UnderReviewWrapper = styled.div`
  .modal-header {
    padding: 30px 50px 28px 50px;
    .btn-close {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }

  .footer {
    button {
      width: 100%;
    }
  }
  .para {
    font-family: ${Fonts.secondary};
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    color: #4c7061;
    padding: 0 1rem;
    line-height: 22px;
  }
  .modal-body {
    padding: 0 50px 40px 50px;
  }
`;
