import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: 20px;
  .top-row {
    .title {
      padding: 15px 0 0 0;
      text-align: center;
      h2 {
        font-size: 25px;
        font-weight: 700;
        color: #002733;
      }
    }
    .form {
      label {
        font-size: 15px;
        font-weight: 700;
        color: #4c6870;
        margin-top: 20px;
      }
      button {
        margin-top: 2px;
      }
    }
    .password-restriction {
      margin-top: 40px;
      .restriction1 {
        margin-bottom: 2px;
        svg.active {
          color: #05ae4b;
        }
      }
      h3 {
        margin-bottom: 13px;
        font-size: 15px;
        font-weight: 500;
      }

      p {
        font-size: 15px;
        font-weight: 500;
        color: #8d8e96;
        display: flex;
        align-items: center;
        line-height: 1.5;
        :last-child {
          span {
            :not(:first-child) {
              margin-right: 7px;
            }
          }
        }
        span {
          :first-child {
            margin-right: 10px;
          }
        }
        .valid {
          color: #05ae4b;
          font-weight: bold;
        }
      }
      .valid {
        color: #05ae4b;
        font-weight: bold;
      }
    }
  }

  .form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .bottom-row {
    text-align: center;
    position: absolute;
    bottom: 68px;
    width: 100%;
    position: satic;
  }
  .skip-btn {
    background-color: #cdefdb;
    color: #05ae4b;
    width: 100%;
  }
  .submit-btn {
    width: 100%;
  }
  .contains {
    color: #05ae4b;
    font-weight: bold;
  }

  .not-contains {
    color: #f44336;
    font-weight: bold;
  }
`;

export default Wrapper;
