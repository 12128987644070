import { useState, useEffect, ChangeEvent } from 'react';
import { Select, SelectItem } from '@nextui-org/react';
import styled from 'styled-components';

type Country = {
  label: string;
  value: string;
  default: boolean;
};

type CountrySelectFormProps = {
  setSelectedCountry: (country: Country) => void;
  value: Country;
};

export default function CountrySelectForm({
  setSelectedCountry,
  value,
}: CountrySelectFormProps) {
  const [countries, setCountries] = useState<Country[]>([]);
  const [isReady, setIsReady] = useState(false); // Track readiness of data
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const newCountries = [
      { label: 'IL +972', value: '+972', default: value.value === '+972' },
    ];
    setCountries(newCountries);
    setIsReady(true); // Mark as ready after countries are set
  };

  const handleChange = (key: string) => {
    const selected = countries.find((c) => c.value === key);
    if (selected) {
      setSelectedCountry(selected);
      setError(null); // Clear error if valid selection is made
    } else {
      setError('ⓘ Country Code is Required.');
    }
  };

  const handleBlur = () => {
    if (!value || !value.value) {
      setError('ⓘ Country Code is Required.');
    }
  };

  return (
    <Wrapper>
      {isReady && (
        <>
          <Select
            isRequired
            defaultSelectedKeys={[value.value]}
            label="Country"
            placeholder="Select a country"
            variant="bordered"
            onChange={(key) => handleChange(String(key))}
            onBlur={handleBlur} // Trigger validation on blur
            isInvalid={!!error} // Add a red border if there is an error
            errorMessage={error} // Display error message
          >
            {countries.map((country) => (
              <SelectItem
                key={String(country.value)}
                value={String(country.value)}
              >
                {country.label}
              </SelectItem>
            ))}
          </Select>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .max-w-xs {
    width: 100%;
    border: none;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;
