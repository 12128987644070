import { useNavigate } from 'react-router-dom';
import { Wrapper } from './login_signup_switch.styled';
import { useDispatch } from 'react-redux';
import { fullLogout } from 'routes/functions/routes_functions';
import { useEffect } from 'react';
import { silal_logo_green } from 'assets/index';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button } from '@nextui-org/react';

function LoginSignupSwitch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    fullLogout(dispatch, navigate);
  }, []);
  return (
    <BackgroundContainer>
      <Wrapper>
        <div className=" d-flex flex-col justify-content-center inner-content container">
          <div
            className="col-12  text-center content"
            style={{
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <img
              src={silal_logo_green}
              alt="logo"
              style={{
                margin: 'auto',
                width: '200px',
              }}
            />{' '}
            {/* // TODO add illustration */}
            <h6
              style={{
                paddingTop: '20px',
              }}
            >
              Welcome to your new <br /> business Eco-System
            </h6>
            <p className="text-center" style={{ maxWidth: '600px' }}>
              <b>Silal</b> helps you manage your business in the online world
              and connect with your customers to achieve more in everyway.
            </p>
          </div>
          <div
            className="col-12 col-lg-6 text-center content"
            style={{
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <div
              className="d-flex flex-col"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => {
                  navigate('/signup');
                }}
                color="success"
                className="min-h-12 mb-3"
                style={{ width: '70%' }}
              >
                Sign up
              </Button>
              <Button
                onClick={() => {
                  navigate('/login');
                }}
                color="success"
                variant="flat"
                className="min-h-12"
                style={{ width: '70%' }}
              >
                Log In
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default LoginSignupSwitch;
