import { Button } from '@nextui-org/react';

type FormFooterProps = {
  // setState: (value: number) => void;
  currentStep: number;
  onClickNext: () => void;
  onClickBack: () => void;
};

function FormFooter({
  currentStep,
  onClickNext,
  onClickBack,
}: FormFooterProps) {
  return (
    <div className="container d-flex justify-content-between align-items-center py-3">
      <Button color="default" className="ml-5" onPress={onClickBack}>
        Back
      </Button>

      <Button onPress={onClickNext} className="mr-5" color="success">
        {currentStep === 3 ? 'Submit' : 'Next'}
      </Button>
    </div>
  );
}

export default FormFooter;
